import { colorsObject } from "data/colors/colorPalettes"
import { colorPallet as c } from "data/colors/colorPalettes"

export const apiVersions = {
  V1: 'v1',
  V2: 'v2'
}

export const apiPaths = {
  ADMIN_USERS: 'admin.users',
  ADMIN_SUBID: 'admin.subid-settings',
  ADMIN_DASHBOARD: 'admin.dashboard',
  ADMIN_LOGS: 'admin.logs',
  ADMIN_LOGS_QUEUE_EMAILS: 'admin.queue-emails',
  ADMIN_LOGS_REQUESTS: 'admin.requests',
  ADMIN_BULK_SUSPEND: 'admin.bulk-suspend',
  ADMIN_USERS_OVERVIEW: 'admin.users-overview',
  ADMIN_SITES: 'admin.sites',
  ADMIN_SITES_GROUPS: 'admin.sites-groups',
  ADMIN_STATISTIC: 'admin.statistic',
  ADMIN_WIDGETS: 'admin.widgets',
  ADMIN_CLICK_LOG: 'admin.click-log',
  ADMIN_PERMISSIONS: 'admin.permission',
  ADMIN_LOGIN_HISTORY: 'admin.login-history',
  ADMIN_PROFILE: 'admin.profile',
  ADMIN_SYSTEM_NOTIFICATION: 'admin.system-notification',
  ADMIN_OVERVIEW: 'admin.overview',
  ADMIN_FAST_LOGIN: 'admin.fast-login',
  ADMIN_CLICK_SETTINGS: 'admin.clicks-settings',
  ADMIN_BIDS: 'admin.bids',
  ADMIN_TOPUPS: 'admin.topups',
  ADMIN_TOPUPS_COUPONS: 'admin.topups-coupons',
  ADMIN_CAMPAIGNS: 'admin.campaigns',
  // ADMIN_UNAPPROVED_CAMPAIGNS: 'admin.campaigns',
  ADMIN_USERS_OVERDRAFT: 'admin.users-overdraft',
  ADMIN_CAMPAIGNS_CREATIVES: 'admin.campaigns-creatives',
  ADMIN_CAMPAIGNS_AUDIENCE: 'admin.campaigns-audience',
  ADMIN_CAMPAIGNS_PRICING: 'admin.campaigns-pricing',
  ADMIN_CAMPAIGNS_LIMITS: 'admin.campaigns-limits',
  ADMIN_REPORT: 'admin.report',
  ADMIN_SYSTEM_EMAILS: 'admin.system-emails',
  ADMIN_USERS_LIMITS: 'admin.user-limits',
  // ADMIN_UNAPPROVED_CREATIVES: 'admin.campaigns-creatives',
  ADMIN_COUNTRIES_BID_LIMITS: 'admin.countries-bid-limits',
  ADMIN_CHANGELOG: 'admin.system-change-log',

  //PUBLISHER PATHS
  PUBLISHER_DASHBOARD: 'publisher.report',
  PUBLISHER_SITES: 'publisher.sites',
  PUBLISHER_STATISTIC: 'publisher.statistic',
  PUBLISHER_PROFILE: 'publisher.profile',
  PUBLISHER_PAYMENTS_REQUEST: 'publisher.payments',
  PUBLISHER_PAYMENTS_SETTINGS: 'publisher.payment-settings',
  PUBLISHER_REFERRAL: 'publisher.referral',
  PUBLISHER_WIDGETS: 'publisher.widgets',
  PUBLISHER_INFO: 'publisher.info',
  PUBLISHER_LOGIN_HISTORY: 'publisher.login-history',
  PUBLISHER_EXCHANGE_SETTINGS: 'publisher.exchange-settings',
  PUBLISHER_REPORT: 'publisher.report',
  PUBLISHER_BALANCE: 'publisher.info',

  //ADVERTISER PATHS
  ADVERTISER_DASHBOARD: 'advertiser.dashboard',
  ADVERTISER_TOPUPS: 'advertiser.topups',
  ADVERTISER_PROFILE: 'advertiser.profile',
  ADVERTISER_REFERRAL: 'advertiser.referral',
  ADVERTISER_LOGIN_HISTORY: 'advertiser.login-history',
  ADVERTISER_REGISTRATION: 'advertiser.registration',
  ADVERTISER_CAMPAIGNS: 'advertiser.campaigns',
  ADVERTISER_CAMPAIGNS_CREATIVES: 'advertiser.campaigns-creatives',
  ADVERTISER_CREATIVES: 'advertiser.campaigns-creatives',
  ADVERTISER_CAMPAIGNS_LIMITS: 'advertiser.campaigns-limits',
  ADVERTISER_CAMPAIGNS_PRICING: 'advertiser.campaigns-pricing',
  ADVERTISER_CAMPAIGNS_AUDIENCE: 'advertiser.campaigns-audience',
  ADVERTISER_INFO: 'advertiser.info',
  NOTIFICATION: 'system-notification',
  ADVERTISER_NOTIFICATIONS: 'advertiser.notifications',
  ADVERTISER_STATS: 'advertiser.report',
  ADVERTISER_BALANCE: 'advertiser.info',
  RESOURCES: 'web'
}

export const apiActions = {
  LIST: 'list',
  GET: 'get',
  CREATE: 'create',
  UPDATE: 'update',
  DELETE: 'delete',
  VALIDATE: 'validate',
  DUPLICATE: 'duplicate',
  SUM: 'sum',
  CHART: 'chart',
  BALANCE: 'balance',
  REPORT: 'report',
  APPROVED: 'approved',
  REJECT: 'reject',
  TOTAL: 'total',
  ARCHIVE: 'archive',
  SET_MANAGER: 'set-manager',
  QUEUE_EMAIL: 'queue-email',
  COPY: 'copy',
  COUNTRIES: 'countries',
  CHECK: 'check',
  SEEN: 'seen',
  COUPON: 'coupon',
  USE_COUPON: 'use-coupon',
  LIST_ASSIGNED: 'list-assigned',
  LIST_NOT_ASSIGNED: 'list-not-assigned',
  UNASSIGN: 'unassign',
  ASSIGN: 'assign',
  BROWSER: 'browsers',
  BROWSER_VERSION: 'browser-versions',
  OS: 'os',
  OS_VERSION: 'os-versions',
  MANAGER: 'manager',
  TOTAL_UNREAD: 'total-unread',
  CAMPAIGNS: 'campaigns',
  DYNAMICS: 'dynamics',
  CONTENT_EXCHANGE_SENT: 'content-exchange-sent',
  CONTENT_EXCHANGE_RECEIVED: 'content-exchange-received',
  SEND_TEST: 'send-test',
  TIPALTI: 'tipalti',
  REQUEST: 'request',
  PUBLISHER: 'publisher',
  ADVERTISER: 'advertiser'
}

export const boolean = {
  boolean: {
    label: 'Boolean true/false',
    data: {
      default: {
        label: 'No',
        value: 'false',
        theme: colorsObject().englishRed,
        booleanValue: 0,
      },
      true: {
        label: 'Yes',
        value: 'true',
        theme: colorsObject().forrestGreenCrayola,
        booleanValue: 1,
      },
      false: {
        label: 'No',
        value: 'false',
        theme: colorsObject().englishRed,
        booleanValue: 2,
      },
    }
  },
  booleanString: {
    label: 'Boolean "true"/"false"',
    data: {
      default: {
        label: 'No',
        value: 'false',
        theme: colorsObject().englishRed,
        booleanValue: 0,
      },
      true: {
        label: 'Yes',
        value: 'true',
        theme: colorsObject().forrestGreenCrayola,
        booleanValue: 1,
      },
      false: {
        label: 'No',
        value: 'false',
        theme: colorsObject().englishRed,
        booleanValue: 2,
      },
    }
  },
  booleanYesNo: {
    label: 'Boolean "Yes"/"No"',
    data: {
      default: {
        label: 'No',
        value: 'no',
        theme: colorsObject().englishRed,
        booleanValue: 0,
      },
      yes: {
        label: 'Yes',
        value: 'yes',
        theme: colorsObject().forrestGreenCrayola,
        booleanValue: 1,
      },
      no: {
        label: 'No',
        value: 'no',
        theme: colorsObject().englishRed,
        booleanValue: 2,
      },
    }
  },
  booleanNumber: {
    label: 'Boolean 1/0',
    data: {
      default: {
        label: 'No',
        value: 0,
        theme: colorsObject().englishRed,
        booleanValue: 0,
      },
      '1': {
        label: 'Yes',
        value: 1,
        theme: colorsObject().forrestGreenCrayola,
        booleanValue: 1,
      },
      '0': {
        label: 'No',
        value: 0,
        theme: colorsObject().englishRed,
        booleanValue: 2,
      },
    }
  }
}

export const common = {
  ...boolean,
  devices: {
    label: 'Devices',
    data: {
      default: {
        label: 'N/A',
        value: null,
        theme: colorsObject().cadetGrey,
      },
      'desktop': {
        label: 'Desktop',
        value: 'desktop',
        theme: colorsObject().starCommandBlue,
      },
      'mobile': {
        label: 'Mobile',
        value: 'mobile',
        theme: colorsObject().rebeccaPurple,
      },
      'tablet': {
        label: 'Tablet',
        value: 'tablet',
        theme: colorsObject().ochre,
      },
      'all': {
        label: 'All',
        value: 'all',
        theme: colorsObject().blush,
      },
      'unknown': {
        label: 'Unknown',
        value: 'unknown',
        theme: colorsObject().darkByzantium,
      }
    },
  },
  empty: {
    label: 'Empty',
    data: {},
  }
}

export const changeLog = {
  changeLogChangeGroup: {
    label: 'Change log change group',
    data: {
      default: {
        label: 'N/A',
        value: null,
        theme: colorsObject().cadetGrey,
      },
      1: {
        label: "Admin",
        value: 1,
        theme: colorsObject().amazon,
      },
      5: {
        label: "Publisher",
        value: 5,
        theme: colorsObject().blueVioletCrayola,
      },
      6: {
        label: "Advertiser",
        value: 6,
        theme: colorsObject().ochre,
      },
    },
  },
  changeLogType: {
    label: 'Change log type',
    data: {
      default: {
        label: 'N/A',
        value: null,
        theme: colorsObject().cadetGrey,
      },
      1: {
        label: 'Improvement',
        value: 1,
        theme: colorsObject().bottleGreen,
      },
      2: {
        label: 'Bug fix',
        value: 2,
        theme: colorsObject().starCommandBlue,
      },
      3: {
        label: 'New feature',
        value: 3,
        theme: colorsObject().amazon,
      }
    },
  }
}

export const campaignsDsp = {
  campaignModel: {
    label: 'Campaign model',
    data: {
      default: {
        label: 'N/A',
        value: null,
        theme: colorsObject().cadetGrey,
      },
      native: {
        label: 'Native',
        value: 'native',
        theme: colorsObject().amazon,
      },
      push: {
        label: 'Push',
        value: 'push',
        theme: colorsObject().burntSienna,
      },
      "in-page-push": {
        label: 'In Page Push',
        value: 'in-page-push',
        theme: colorsObject().flickrPink,
      },
      pop: {
        label: 'Pop',
        value: 'pop',
        theme: colorsObject().burntSienna,
      },
      smart: {
        label: 'Smart',
        value: 'smart',
        theme: colorsObject().rebeccaPurple,
      },
      'all-native-smart': {
        label: 'All Native & Smart',
        value: 'all-native-smart',
        theme: colorsObject().starCommandBlue,
      },
      'in-text': {
        label: 'In Text',
        value: 'in-text',
        theme: colorsObject().ochre,
      }
    },
  },
  campaignStatus: {
    label: 'Campaign status',
    data: {
      default: {
        label: 'N/A',
        value: null,
        theme: colorsObject().cadetGrey,
      },
      active: {
        label: 'Active',
        value: 'active',
        theme: colorsObject().forrestGreenCrayola,
      },
      inactive: {
        label: 'Inactive',
        value: 'inactive',
        theme: colorsObject().englishRed,
      },
      paused: {
        label: 'Paused',
        value: 'paused',
        theme: colorsObject().orangeYellowCrayola,
      },
      archive: {
        label: 'Archive',
        value: 'archive',
        theme: colorsObject().bdazzledBlue,
      },
      suspended: {
        label: 'Suspended',
        value: 'suspended',
        theme: colorsObject().englishViolet,
      },
      disabled: {
        label: 'Disabled',
        value: 'disabled',
        theme: colorsObject().brickRed,
      },
      "hourly-budget-limit": {
        label: 'Hourly budget limit',
        value: 'hourly-budget-limit',
        theme: colorsObject().starCommandBlue,
      },
      "daily-budget-limit": {
        label: 'Daily budget limit',
        value: 'daily-budget-limit',
        theme: colorsObject().darkByzantium,
      },
      "montly-budget-limit": {
        label: 'Monthly budget limit',
        value: 'montly-budget-limit',
        theme: colorsObject().littleBoyBlue,
      },
      "lifetime-budget-limit": {
        label: 'Lifetime budget limit',
        value: 'lifetime-budget-limit',
        theme: colorsObject().fuzzyWuzzy,
      },
      //clicks
      "hourly-clicks-limit": {
        label: 'Hourly clicks limit',
        value: 'hourly-clicks-limit',
        theme: colorsObject().cornflowerBlue,
      },
      "daily-clicks-limit": {
        label: 'Daily clicks limit',
        value: 'daliy-clicks-limit',
        theme: colorsObject().wisteria,
      },
      "montly-clicks-limit": {
        label: 'Monthly clicks limit',
        value: 'montly-clicks-limit',
        theme: colorsObject().feldgrau,
      },
      //impressions
      "hourly-impressions-limit": {
        label: 'Hourly impressions limit',
        value: 'hourly-impressions-limit',
        theme: colorsObject().keyLime,
      },
      "daily-impressions-limit": {
        label: 'Daily impressions limit',
        value: 'daily-impressions-limit',
        theme: colorsObject().keyLime,
      },
      "montly-impressions-limit": {
        label: 'Montly impressions limit',
        value: 'montly-impressions-limit',
        theme: colorsObject().sunray,
      },

      "lifetime-impressions-limit": {
        label: 'Lifetime impressions limit',
        value: 'lifetime-impressions-limit',
        theme: colorsObject().deserSand,
      },

      implementing: {
        label: 'Implementing',
        value: 'implementing',
        theme: colorsObject().olivine,
      },
      pending: {
        label: 'Pending',
        value: 'pending',
        theme: colorsObject().olivine,
      },
      delete: {
        label: 'Delete',
        value: 'delete',
        theme: colorsObject().rhythm,
      },
    },
  },
  campaignPricingModel: {
    label: 'Campaign pricing model',
    data: {
      default: {
        label: 'N/A',
        value: null,
        theme: colorsObject().cadetGrey,
      },
      cpc: {
        label: 'CPC',
        value: 'cpc',
        theme: colorsObject().fuzzyWuzzy,
      },
      cpm: {
        label: 'CPM',
        value: 'cpm',
        theme: colorsObject().bdazzledBlue,
      },
      cpa: {
        label: 'CPA',
        value: 'cpa',
        theme: colorsObject().blueVioletCrayola,
      },
    }
  },
  campaignType: {
    label: 'Campaign type',
    data: {
      default: {
        label: 'N/A',
        value: null,
        theme: colorsObject().cadetGrey,
      },
      internal: {
        label: 'Internal',
        value: 'internal',
        theme: colorsObject().starCommandBlue,
      },
      external: {
        label: 'External',
        value: 'external',
        theme: colorsObject().royalPurple,
      }
    }
  },
  optimizationMethod: {
    label: 'Optimization method',
    data: {
      default: {
        label: 'N/A',
        value: null,
        theme: colorsObject().cadetGrey,
      },
      "sales": {
        label: 'Sales',
        value: 'sales',
        theme: colorsObject().fuzzyWuzzy,
      },
      "leads": {
        label: 'Leads',
        value: 'leads',
        theme: colorsObject().bdazzledBlue,
      },
      "website_traffic": {
        label: 'Website traffic',
        value: 'website_traffic',
        theme: colorsObject().blueVioletCrayola,
      },
      "product_and_brand_consideration": {
        label: 'Product and brand consideration',
        value: 'product_and_brand_consideration',
        theme: colorsObject().ochre,
      },
      "brand_awareness_and_reach": {
        label: 'Brand awareness and reach',
        value: 'brand_awareness_and_reach',
        theme: colorsObject().rhythm,
      },
      "app_promotion": {
        label: 'App promotion',
        value: 'app_promotion',
        theme: colorsObject().viridian,
      },
    }
  },
  campaignVertical: {
    label: 'Campaign vertical',
    data: {
      default: {
        label: 'N/A',
        value: null,
        theme: colorsObject().cadetGrey,
      },
      37: {
        label: 'Nutra & Diet',
        value: '37',
        theme: colorsObject().cadetGrey,
      },
      36: {
        label: 'Recreational Drugs & Weeds',
        value: '36',
        theme: colorsObject().cadetGrey,
      },
      35: {
        label: 'Pharmacy',
        value: '35',
        theme: colorsObject().cadetGrey,
      },
      34: {
        label: 'Health & Beauty',
        value: '34',
        theme: colorsObject().cadetGrey,
      },
      33: {
        label: 'Shopping',
        value: '33',
        theme: colorsObject().cadetGrey,
      },
      32: {
        label: 'Travel booking sites',
        value: '32',
        theme: colorsObject().cadetGrey,
      },
      31: {
        label: 'Shopping & Travel',
        value: '31',
        theme: colorsObject().cadetGrey,
      },
      30: {
        label: 'Hobbies & Interests',
        value: '30',
        theme: colorsObject().cadetGrey,
      },
      29: {
        label: 'Education/Personal development',
        value: '29',
        theme: colorsObject().cadetGrey,
      },
      28: {
        label: 'Service & Repairs',
        value: '28',
        theme: colorsObject().cadetGrey,
      },
      27: {
        label: 'Careers',
        value: '27',
        theme: colorsObject().cadetGrey,
      },
      26: {
        label: 'Binary Options/Trading',
        value: '27',
        theme: colorsObject().starCommandBlue,
      },
      25: {
        label: 'Real Estate',
        value: '25',
        theme: colorsObject().starCommandBlue,
      },
      24: {
        label: 'Investing',
        value: '24',
        theme: colorsObject().starCommandBlue,
      },
      23: {
        label: 'Loans',
        value: '23',
        theme: colorsObject().starCommandBlue,
      },
      22: {
        label: 'Desktop - Browser Extensions',
        value: '22',
        theme: colorsObject().ochre,
      },
      21: {
        label: 'Desktop - Smartlink',
        value: '21',
        theme: colorsObject().ochre,
      },
      20: {
        label: 'Desktop - Utility & VPN',
        value: '20',
        theme: colorsObject().ochre,
      },
      19: {
        label: 'Desktop - Antivirus',
        value: '19',
        theme: colorsObject().ochre,
      },
      18: {
        label: 'Desktop - Software',
        value: '18',
        theme: colorsObject().ochre,
      },
      17: {
        label: 'Desktop - Games',
        value: '17',
        theme: colorsObject().ochre,
      },
      15: {
        label: 'Lead Generation',
        value: '15',
        theme: colorsObject().rhythm,
      },
      14: {
        label: 'Mainstream VOD/Streaming',
        value: '14',
        theme: colorsObject().rhythm,
      },
      13: {
        label: 'Casino & Gambling',
        value: '13',
        theme: colorsObject().rhythm,
      },
      12: {
        label: 'Pin Submit',
        value: '12',
        theme: colorsObject().rhythm,
      },
      11: {
        label: 'Dating',
        value: '11',
        theme: colorsObject().rhythm,
      },
      10: {
        label: 'Crypto Offers',
        value: '10',
        theme: colorsObject().rhythm,
      },
      9: {
        label: 'Sports Betting',
        value: '9',
        theme: colorsObject().rhythm,
      },
      8: {
        label: 'Surveys & Sweeps',
        value: '8',
        theme: colorsObject().rhythm,
      },
      7: {
        label: 'Mobile App - Games',
        value: '7',
        theme: colorsObject().skobeloff,
      },
      6: {
        label: 'Mobile App - Other',
        value: '6',
        theme: colorsObject().skobeloff,
      },
      5: {
        label: 'Mobile App - Casino & Gambling',
        value: '5',
        theme: colorsObject().skobeloff,
      },
      4: {
        label: 'Mobile App - Antivirus',
        value: '4',
        theme: colorsObject().skobeloff,
      },
      3: {
        label: 'Mobile App - Dating',
        value: '3',
        theme: colorsObject().skobeloff,
      },
      2: {
        label: 'Mobile App - Utility & VPN',
        value: '2',
        theme: colorsObject().skobeloff,
      },
      1: {
        label: 'Mobile App - Smartlink',
        value: '1',
        theme: colorsObject().skobeloff,
      },



    }
  }
}

export const creatives = {
  creativesStatus: {
    label: 'Creatives status',
    data: {
      default: {
        label: "N/A",
        value: null,
        theme: colorsObject().cadetGrey,
      },
      'active': {
        label: "Active",
        value: 'active',
        theme: colorsObject().forrestGreenCrayola,
      },
      'inactive': {
        label: "Inactive",
        value: 'inactive',
        theme: colorsObject().englishRed,
      },
      'suspended': {
        label: "Suspended",
        value: 'suspended',
        theme: colorsObject().englishViolet,
      },
    }
  }
}


export const user = {
  usersStatus: {
    label: 'Users status',
    data: {
      default: {
        label: "Unknown",
        value: null,
        theme: colorsObject().cadetGrey,
      },
      1: {
        label: "Admin",
        value: 1,
        theme: colorsObject().amazon,
      },
      5: {
        label: "Publisher",
        value: 5,
        theme: colorsObject().blueVioletCrayola,
      },
      6: {
        label: "Advertiser",
        value: 6,
        theme: colorsObject().ochre,
      },
      99: {
        label: "Banned",
        value: 99,
        theme: colorsObject().englishRed,
      },
    }
  },
  usersType: {
    label: 'Users type',
    data: {
      default: {
        label: "Unknown",
        value: null,
        theme: colorsObject().cadetGrey,
      },
      1: {
        label: "Internal",
        value: 1,
        theme: colorsObject().starCommandBlue,
      },
      2: {
        label: "External",
        value: 2,
        theme: colorsObject().royalPurple,
      },
    }
  },
}


export const exchangeSettings = {
  exchangeStatus: {
    label: 'Exchange status',
    data: {
      default: {
        label: 'N/A',
        value: null,
        theme: colorsObject().cadetGrey,
      },
      active: {
        label: 'Active',
        value: 'active',
        theme: colorsObject().forrestGreenCrayola,
      },
      inactive: {
        label: 'Inactive',
        value: 'inactive',
        theme: colorsObject().englishRed,
      },
    }
  },

  exchangeType: {
    label: 'Exchange type',
    data: {
      default: {
        label: 'N/A',
        value: null,
        theme: colorsObject().cadetGrey,
      },
      blacklist: {
        label: 'Blacklist',
        value: 'blacklist',
        theme: colorsObject().onyx,
      },
      whitelist: {
        label: 'Whitelist',
        value: 'whitelist',
        theme: colorsObject().eggshell,
      },
    }
  },
}

export const topups = {
  topupsType: {
    label: 'Topups type',
    data: {
      default: {
        label: 'N/A',
        value: null,
        theme: colorsObject().cadetGrey,
      },
      'credit-card': {
        label: 'Credit card',
        value: 'credit-card',
        theme: colorsObject().ochre,
      },
      wire: {
        label: 'Wire',
        value: 'wire',
        theme: colorsObject().vividTangarine,
      },
      coupon: {
        label: 'Coupon',
        value: 'coupon',
        theme: colorsObject().rhythm,
      },
      bonus: {
        label: 'Bonus',
        value: 'bonus',
        theme: colorsObject().viridian,
      },
      'usdt-erc20': {
        label: 'USDT ERC20',
        value: 'usdt-erc20',
        theme: colorsObject().feldgrau
      },
      'usdt-trc20': {
        label: 'USDT TRC20',
        value: 'usdt-trc20',
        theme: colorsObject().starCommandBlue
      },

    },
  },
  topupsStatus: {
    label: 'Topups status',
    data: {
      default: {
        label: 'N/A',
        value: null,
        theme: colorsObject().cadetGrey,
      },
      created: {
        label: 'Created',
        value: 'created',
        theme: colorsObject().bdazzledBlue,
      },
      paid: {
        label: 'Paid',
        value: 'paid',
        theme: colorsObject().bottleGreen,
      },
      rejects: {
        label: 'Rejected',
        value: 'rejects',
        theme: colorsObject().brickRed,
      },
      refund: {
        label: 'Refund',
        value: 'refund',
        theme: colorsObject().ochre,
      }
    },
  },
}


export const groups = {
  groupsStatus: {
    label: 'Groups status',
    data: {
      default: {
        label: 'N/A',
        value: null,
        theme: colorsObject().cadetGrey,
      },
      active: {
        label: 'Active',
        value: 'active',
        theme: colorsObject().forrestGreenCrayola,
      },
      inactive: {
        label: 'Inactive',
        value: 'inactive',
        theme: colorsObject().englishRed,
      },
    }
  },
}

export const coupons = {
  couponsType: {
    label: 'Coupons type',
    data: {
      default: {
        label: 'N/A',
        value: null,
        theme: colorsObject().cadetGrey,
      },
      'once': {
        label: 'Once',
        value: 'once',
        theme: colorsObject().forrestGreenCrayola,
      },
      'limited': {
        label: 'Limited',
        value: 'limited',
        theme: colorsObject().ochre,
      },
      'multiple': {
        label: 'Multiple',
        value: 'multiple',
        theme: colorsObject().starCommandBlue,
      },
    }
  },
}

export const bids = {
  bidsStrategy: {
    label: 'Bids strategy',
    data: {
      default: {
        label: 'N/A',
        value: null,
        theme: colorsObject().cadetGrey,
      },
      exact: {
        label: 'Exact',
        value: 'exact',
        theme: colorsObject().russianGreen,
      },
      floorAdd: {
        label: 'Floor add',
        value: 'floor_add',
        theme: colorsObject().feldgrau,
      },
      floorMultiply: {
        label: 'Floor multiply',
        value: 'floor_multiply',
        theme: colorsObject().unbleachedSilk,
      },
      priceAdd: {
        label: 'Price add',
        value: 'price_add',
        theme: colorsObject().middleBluePurple,
      },
      priceMultiply: {
        label: 'Price multiply',
        value: 'price_multiply',
        theme: colorsObject().englishRed,
      }
    },
  },
  bidsModel: {
    label: 'Bids model',
    data: {
      default: {
        label: 'N/A',
        value: null,
        theme: colorsObject().cadetGrey,
      },
      cpc: {
        label: 'CPC',
        value: 'cpc',
        theme: colorsObject().amazon,
      },
      cpm: {
        label: 'CPM',
        value: 'cpm',
        theme: colorsObject().englishRed,
      }
    },
  },
  bidsStatus: {
    label: 'Bids status',
    data: {
      default: {
        label: 'N/A',
        value: null,
        theme: colorsObject().cadetGrey,
      },
      'active': {
        label: 'Active',
        value: 'active',
        theme: colorsObject().amazon,
      },
      'inactive': {
        label: 'Inactive',
        value: 'inactive',
        theme: colorsObject().englishRed,
      }
    },
  }
}


export const widgets = {
  widgetsStatus: {
    label: 'Widgets status',
    data: {
      default: {
        label: 'N/A',
        value: null,
        theme: colorsObject().cadetGrey,
      },
      2: {
        label: 'Awaiting approval',
        value: '2',
        theme: colorsObject().mantis,
      },
      3: {
        label: 'Active',
        value: '3',
        theme: colorsObject().forrestGreenCrayola,
      },
      4: {
        label: 'Suspend',
        value: '4',
        theme: colorsObject().barnRed,
      },
      5: {
        label: 'Reject',
        value: '5',
        theme: colorsObject().ultraRed,
      },
    }
  }
}

export const sites = {
  sitesStatus: {
    label: 'Sites status',
    data: {
      default: {
        label: 'N/A',
        value: null,
        theme: colorsObject().cadetGrey,
      },
      1: {
        label: 'Verification',
        value: '1',
        theme: colorsObject().peach,
      },
      2: {
        label: 'Awaiting approval',
        value: '2',
        theme: colorsObject().mantis,
      },
      3: {
        label: 'Active',
        value: '3',
        theme: colorsObject().forrestGreenCrayola,
      },
      4: {
        label: 'Suspend',
        value: '4',
        theme: colorsObject().barnRed,
      },
      5: {
        label: 'Reject',
        value: '5',
        theme: colorsObject().ultraRed,
      },
    }
  },
  sitesCategory: {
    label: 'Sites category',
    data: {
      default: {
        label: 'N/A',
        value: null,
        theme: colorsObject().cadetGrey,
      },
      1: {
        label: 'Books and Literature',
        value: "1",
        theme: colorsObject().cerise,
      },
      2: {
        label: 'Business and Finance',
        value: "2",
        theme: colorsObject().starCommandBlue,
      },
      4: {
        label: 'Careers',
        value: "4",
        theme: colorsObject().skobeloff,
      },
      6: {
        label: 'Education',
        value: "6",
        theme: colorsObject().jazzberryJam,
      },
      7: {
        label: 'Events and attractions',
        value: "7",
        theme: colorsObject().copperRed,
      },
      8: {
        label: 'Family and relationships',
        value: "8",
        theme: colorsObject().paolaVeroneseGreen,
      },
      9: {
        label: 'Fine art',
        value: "9",
        theme: colorsObject().flickrPink,
      },
      10: {
        label: 'Food and drink',
        value: "10",
        theme: colorsObject().orchid,
      },
      11: {
        label: 'Healthy living',
        value: "11",
        theme: colorsObject().fandago,
      },
      12: {
        label: 'Home and garden',
        value: "12",
        theme: colorsObject().maximumBluePurple,
      },
      13: {
        label: 'Hobbies and interests',
        value: "13",
        theme: colorsObject().midnightEagleGreen,
      },
      14: {
        label: 'Medical health',
        value: "14",
        theme: colorsObject().sugarPlum,
      },
      15: {
        label: 'Movies',
        value: "15",
        theme: colorsObject().cornflowerBlue,
      },
      16: {
        label: 'Music and audio',
        value: "16",
        theme: colorsObject().middlePurple,
      },
      17: {
        label: 'News portal',
        value: "17",
        theme: colorsObject().darkSlateGray,
      },
      18: {
        label: 'News and politics',
        value: "18",
        theme: colorsObject().brunswickGreen,
      },
      19: {
        label: 'Non-standard content',
        value: "19",
        theme: colorsObject().ochre,
      },
      20: {
        label: 'Pets',
        value: "20",
        theme: colorsObject().bottleGreen,
      },
      21: {
        label: 'Pop culture',
        value: "21",
        theme: colorsObject().orangeYellowCrayola,
      },
      22: {
        label: 'Religion and spirituality',
        value: "22",
        theme: colorsObject().midnightGreenEagleGreen,
      },
      23: {
        label: 'Science',
        value: "23",
        theme: colorsObject().naplesYellow,
      },
      24: {
        label: 'Shopping',
        value: "24",
        theme: colorsObject().ming,
      },
      25: {
        label: 'Sports',
        value: "25",
        theme: colorsObject().viridian,
      },
      26: {
        label: 'Style and fashion',
        value: "26",
        theme: colorsObject().asparagus,
      },
      27: {
        label: 'Technology and computing',
        value: "27",
        theme: colorsObject().englishRed,
      },
      28: {
        label: 'Television',
        value: "28",
        theme: colorsObject().lavenderFloral,
      },
      29: {
        label: 'Travel',
        value: "29",
        theme: colorsObject().barnRed,
      },
      30: {
        label: 'Uncategorized',
        value: "30",
        theme: colorsObject().unbleachedSilk,
      },
      31: {
        label: 'Video gaming',
        value: "31",
        theme: colorsObject().maximumBlueGreen,
      },
    }
  },

  sitesTrafficType: {
    label: 'Sites traffic type',
    data: {
      default: {
        label: 'N/A',
        value: null,
        theme: colorsObject().cadetGrey,
      },
      1: {
        label: 'All',
        value: '1',
        theme: colorsObject().forrestGreenCrayola,
      },
      2: {
        label: 'Mainstream',
        value: '2',
        theme: colorsObject().peach,
      },
      3: {
        label: 'Adult',
        value: '3',
        theme: colorsObject().englishRed,
      },
    }
  },

  widgetsFormats: {
    label: 'Widgets formats',
    data: {
      default: {
        label: 'N/A',
        value: null,
        theme: colorsObject().cadetGrey,
      },
      1: {
        label: 'Native',
        value: 'native',
        theme: colorsObject().forrestGreenCrayola,
      },
      2: {
        label: 'In-Text',
        value: 'in-text',
        theme: colorsObject().peach,
      },
    }
  },

  sitesTypeOfMonetization: {
    label: 'Type of monetization',
    data: {
      default: {
        label: 'N/A',
        value: null,
        theme: colorsObject().cadetGrey,
      },
      1: {
        label: 'CPC',
        value: '1',
        theme: colorsObject().forrestGreenCrayola,
      },
      2: {
        label: 'CPM',
        value: '2',
        theme: colorsObject().peach,
      },
      3: {
        label: 'CPA',
        value: '3',
        theme: colorsObject().mantis,
      },
    }
  },

  sitesTraffic: {
    label: 'Sites traffic',
    data: {
      default: {
        label: 'N/A',
        value: null,
        theme: colorsObject().cadetGrey,
      },
      1: {
        label: '< 1000',
        value: '1',
        theme: colorsObject().forrestGreenCrayola,
      },
      2: {
        label: '1000 - 5000',
        value: '2',
        theme: colorsObject().peach,
      },
      3: {
        label: '10000 - 50000',
        value: '3',
        theme: colorsObject().eggshell,
      },
      4: {
        label: '50000 - 100000',
        value: '4',
        theme: colorsObject().wisteria,
      },
      5: {
        label: '> 100000',
        value: '4',
        theme: colorsObject().wisteria,
      },
    }
  },



}

export const systemNotification = {
  notificationStatus: {
    label: 'System notification status',
    data: {
      default: {
        label: 'N/A',
        value: null,
        theme: colorsObject().cadetGrey,
      },
      1: {
        label: 'Info',
        value: '1',
        theme: colorsObject().starCommandBlue,
      },
      2: {
        label: 'Warning',
        value: '2',
        theme: colorsObject().orangeYellowCrayola,
      },
      3: {
        label: 'Danger',
        value: '3',
        theme: colorsObject().englishRed,
      }

      // 4: {
      //   label: 'Success',
      //   value: '4',
      //   theme: colorsObject().forrestGreenCrayola,
      // }
    }
  },
  department: {
    label: 'System notification department',
    data: {
      default: {
        label: 'N/A',
        value: null,
        theme: colorsObject().cadetGrey,
      },
      'all': {
        label: 'All',
        value: 'all',
        theme: colorsObject().rhythm,
      },
      'admin': {
        label: 'Admin',
        value: 'admin',
        theme: colorsObject().russianGreen,
      },
      'publisher': {
        label: 'Publisher',
        value: 'publisher',
        theme: colorsObject().starCommandBlue,
      },
      'advertiser': {
        label: 'Advertiser',
        value: 'advertiser',
        theme: colorsObject().ochre,
      }

    }
  },
  duration: {
    label: 'System notification duration',
    data: {
      default: {
        label: 'N/A',
        value: null,
        theme: colorsObject().cadetGrey,
      },
      'Expired': {
        label: 'Expired',
        value: 'Expired',
        theme: colorsObject().ochre,
      },
      '3600': {
        label: '1h',
        value: '3600',
        theme: colorsObject().rhythm,
      },
      "7200": {
        label: "2h",
        value: "7200",
        theme: colorsObject().rhythm,
      },
      "10800": {
        label: "3h",
        value: "10800",
        theme: colorsObject().rhythm,
      },
      "14400": {
        label: "4h",
        value: "14400",
        theme: colorsObject().rhythm,
      },
      "21600": {
        label: "6h",
        value: "21600",
        theme: colorsObject().rhythm,
      },
      "28800": {
        label: "8h",
        value: "28800",
        theme: colorsObject().rhythm,
      },
      "36000": {
        label: "10h",
        value: "36000",
        theme: colorsObject().rhythm,
      },
      "43200": {
        label: "12h",
        value: "43200",
        theme: colorsObject().rhythm,
      },
      "86400": {
        label: "24h",
        value: "86400",
        theme: colorsObject().rhythm,
      },
      "172800": {
        label: "48h",
        value: "172800",
        theme: colorsObject().rhythm,
      },
      "259200": {
        label: "72h",
        value: "259200",
        theme: colorsObject().rhythm,
      }


    }
  },
}

export const sitesGroups = {
  status: {
    label: 'Site group status',
    data: {
      default: {
        label: 'N/A',
        value: null,
        theme: colorsObject().cadetGrey,
      },
      'active': {
        label: 'Active',
        value: 'active',
        theme: colorsObject().forrestGreenCrayola,
      },
      'inactive': {
        label: 'Inactive',
        value: 'inactive',
        theme: colorsObject().englishRed,
      }
    }
  },
}

export const subid = {
  status: {
    label: 'SubID status',
    data: {
      default: {
        label: 'N/A',
        value: null,
        theme: colorsObject().cadetGrey,
      },
      'active': {
        label: 'Active',
        value: 'active',
        theme: colorsObject().forrestGreenCrayola,
      },
      'inactive': {
        label: 'Inactive',
        value: 'inactive',
        theme: colorsObject().englishRed,
      }
    }
  },
}



export const payments = {
  paymentsMethod: {
    label: 'Payments method',
    data: {
      default: {
        label: 'N/A',
        value: null,
        theme: colorsObject().cadetGrey
      },
      'invoice': {
        label: 'Invoice',
        value: 'invoice',
        theme: colorsObject().keyLime
      },
      'tipalti': {
        label: 'Tipalti',
        value: 'tipalti',
        theme: colorsObject().ochre
      },
      'usdt-erc20': {
        label: 'USDT ERC20',
        value: 'usdt-erc20',
        theme: colorsObject().feldgrau
      },
      'usdt-trc20': {
        label: 'USDT TRC20',
        value: 'usdt-trc20',
        theme: colorsObject().starCommandBlue
      },
      'webmoney': {
        label: 'Web Money',
        value: 'webmoney',
        theme: colorsObject().cerise,
      },
      'capitalist-internal': {
        label: 'Capitalist Internal Payment',
        value: 'capitalist-internal',
        theme: colorsObject().skobeloff,
      },
    }
  },
  paymentsMethodCreate: {
    label: 'Payments method',
    data: {
      default: {
        label: 'N/A',
        value: null,
        theme: colorsObject().cadetGrey
      },
      'invoice': {
        label: 'Invoice',
        value: 'invoice',
        theme: colorsObject().keyLime
      },
      'tipalti': {
        label: 'Tipalti',
        value: 'tipalti',
        theme: colorsObject().ochre
      },
      'usdt-erc20': {
        label: 'USDT ERC20',
        value: 'usdt-erc20',
        theme: colorsObject().feldgrau
      },
      'usdt-trc20': {
        label: 'USDT TRC20',
        value: 'usdt-trc20',
        theme: colorsObject().starCommandBlue
      },
      'webmoney': {
        label: 'Web Money',
        value: 'webmoney',
        theme: colorsObject().cerise,
      },
      'capitalist-internal': {
        label: 'Capitalist Internal Payment',
        value: 'capitalist-internal',
        theme: colorsObject().skobeloff,
      },
    }
  },
  methodType: {
    label: 'Method',
    data: {
      default: {
        label: 'N/A',
        value: null,
        theme: colorsObject().cadetGrey
      },
      'GET': {
        label: 'GET',
        value: 'GET',
        theme: colorsObject().burntSienna
      },
      'POST': {
        label: 'POST',
        value: 'POST',
        theme: colorsObject().bottleGreen
      },
      'DELETE': {
        label: 'DELETE',
        value: 'DELETE',
        theme: colorsObject().englishRed
      },
    }
  },
  paymentsStatus: {
    label: 'Payments status',
    data: {
      default: {
        label: 'N/A',
        value: null,
        theme: colorsObject().cadetGrey
      },
      1: {
        label: 'Created',
        value: 1,
        theme: colorsObject().bdazzledBlue
      },
      2: {
        label: 'Approved',
        value: 2,
        theme: colorsObject().bottleGreen
      },
      3: {
        label: 'Rejected',
        value: 3,
        theme: colorsObject().brickRed
      },
      4: {
        label: 'Paid',
        value: 4,
        theme: colorsObject().lightGreenCustom
      },
      5: {
        label: 'Returned',
        value: 5,
        theme: colorsObject().pumpkin
      }
    }
  }
}

export const paymentSettings = {
  paymentSettingsStatus: {
    label: 'Payment settings status',
    data: {
      default: {
        label: 'N/A',
        value: null,
        theme: colorsObject().cadetGrey,
      },
      'active': {
        label: 'Active',
        value: 'active',
        theme: colorsObject().forrestGreenCrayola,
      },
      'inactive': {
        label: 'Inactive',
        value: 'inactive',
        theme: colorsObject().bdazzledBlue,
      },
      'suspended': {
        label: 'Suspended',
        value: 'suspended',
        theme: colorsObject().englishRed,
      },
    }
  },
  paymentSettingsAddStatus: {
    label: 'Payment settings add status',
    data: {
      default: {
        label: 'N/A',
        value: null,
        theme: colorsObject().cadetGrey,
      },
      'active': {
        label: 'Active',
        value: 'active',
        theme: colorsObject().forrestGreenCrayola,
      },
      'inactive': {
        label: 'Inactive',
        value: 'inactive',
        theme: colorsObject().bdazzledBlue,
      }
    }
  }
}




export const loginHistory = {
  loginHistoryStatus: {
    label: 'Login History Status',
    data: {
      default: {
        label: 'N/A',
        value: null,
        theme: colorsObject().cadetGrey,
      },
      1: {
        label: 'Success',
        value: 1,
        theme: colorsObject().forrestGreenCrayola,
      },
      2: {
        label: 'Error',
        value: 2,
        theme: colorsObject().englishRed,
      }
    },
  },
  loginHistoryDevice: {
    label: 'Login History Device',
    data: {
      default: {
        label: 'N/A',
        value: null,
        theme: colorsObject().cadetGrey,
      },
      'desktop': {
        label: 'Desktop',
        value: 'desktop',
        theme: colorsObject().fuzzyWuzzy,
      },
      'mobile': {
        label: 'Mobile',
        value: 'mobile',
        theme: colorsObject().bdazzledBlue,
      },
      'tablet': {
        label: 'tablet',
        value: 'tablet',
        theme: colorsObject().blueVioletCrayola,
      }
    },
  },
}




export const httpCode = {
  httpCodeStatus: {
    label: 'Htpp code status',
    data: {
      default: {
        label: 'N/A',
        value: null,
        theme: colorsObject().cadetGrey
      },
      0: {
        label: '0 Resposne not received',
        value: 0,
        theme: colorsObject().darkByzantium
      },
      200: {
        label: '200 OK',
        value: 200,
        theme: colorsObject().forrestGreenCrayola
      },
      202: {
        label: '202 Accepted',
        value: 202,
        theme: colorsObject().forrestGreenCrayola
      },
      203: {
        label: '203 Non-Authoritative Information',
        value: 203,
        theme: colorsObject().forrestGreenCrayola
      },
      204: {
        label: '204 No Content',
        value: 204,
        theme: colorsObject().forrestGreenCrayola
      },
      205: {
        label: '205 Reset Content',
        value: 205,
        theme: colorsObject().forrestGreenCrayola
      },
      206: {
        label: '206 Partial Content',
        value: 206,
        theme: colorsObject().forrestGreenCrayola
      },
      300: {
        label: '300 Multiple Choice',
        value: 300,
        theme: colorsObject().forrestGreenCrayola
      },
      301: {
        label: '301 Moved permanently',
        value: 301,
        theme: colorsObject().sunray
      },
      302: {
        label: '302 Found',
        value: 302,
        theme: colorsObject().sunray
      },
      303: {
        label: '303 See Other',
        value: 303,
        theme: colorsObject().sunray
      },
      304: {
        label: '304 Not modified',
        value: 304,
        theme: colorsObject().sunray
      },
      307: {
        label: '307 Temporary Redirect',
        value: 305,
        theme: colorsObject().sunray
      },
      308: {
        label: '308 Permament Redirect',
        value: 308,
        theme: colorsObject().sunray
      },
      400: {
        label: '400 Bad Request',
        value: 400,
        theme: colorsObject().burntSienna
      },
      401: {
        label: '401 Unathorized',
        value: 401,
        theme: colorsObject().burntSienna
      },
      403: {
        label: '403 Forbidden',
        value: 403,
        theme: colorsObject().burntSienna
      },
      405: {
        label: '405 Method Not Allowed',
        value: 405,
        theme: colorsObject().burntSienna
      },
      406: {
        label: '406 Not Acceptable',
        value: 406,
        theme: colorsObject().burntSienna
      },
      407: {
        label: '407 Proxy Authentication Required',
        value: 407,
        theme: colorsObject().burntSienna
      },
      408: {
        label: '408 Request Timeout',
        value: 408,
        theme: colorsObject().burntSienna
      },
      409: {
        label: '409 Conflict',
        value: 409,
        theme: colorsObject().burntSienna
      },
      410: {
        label: '410 Gone',
        value: 410,
        theme: colorsObject().burntSienna
      },
      411: {
        label: '411 Length Required',
        value: 411,
        theme: colorsObject().burntSienna
      },
      412: {
        label: '412 Precondition Failed',
        value: 412,
        theme: colorsObject().burntSienna
      },
      413: {
        label: '412 Payload Too Large',
        value: 413,
        theme: colorsObject().burntSienna
      },
      414: {
        label: '414 Uri Too Long',
        value: 414,
        theme: colorsObject().burntSienna
      },
      415: {
        label: '415 Unsupported Media Type',
        value: 414,
        theme: colorsObject().burntSienna
      },
      416: {
        label: '416 Range Not Satisfiable',
        value: 416,
        theme: colorsObject().burntSienna
      },
      417: {
        label: '417 Expectation Failed',
        value: 417,
        theme: colorsObject().burntSienna
      },
      426: {
        label: '426 Upgrade Required',
        value: 426,
        theme: colorsObject().burntSienna
      },
      428: {
        label: '428 Precondition Required',
        value: 428,
        theme: colorsObject().burntSienna
      },
      429: {
        label: '429 Too Many Requests',
        value: 429,
        theme: colorsObject().burntSienna
      },
      431: {
        label: '431 Request Header Fields Too Large',
        value: 431,
        theme: colorsObject().burntSienna
      },
      500: {
        label: '500 Internal Server Error',
        value: 500,
        theme: colorsObject().englishRed
      },
      501: {
        label: '501 Not Implemented',
        value: 501,
        theme: colorsObject().englishRed
      },
      502: {
        label: '502 Bad Gateway',
        value: 502,
        theme: colorsObject().englishRed
      },
      503: {
        label: '503 Service Unavailable',
        value: 503,
        theme: colorsObject().englishRed
      },
      504: {
        label: '504 Gateway Timeout',
        value: 504,
        theme: colorsObject().englishRed
      },
      505: {
        label: '505 HTTP Version Not Supported',
        value: 505,
        theme: colorsObject().englishRed
      },
      510: {
        label: '510 Not Extended',
        value: 510,
        theme: colorsObject().englishRed
      },
      511: {
        label: '511 Network Authentication Required',
        value: 511,
        theme: colorsObject().englishRed
      },
    }
  }
}








//OLD ENUMS

export const enumUserStatus = {
  default: {
    label: "Admin",
    value: 1,
    color: c.amazon.textColor,
    background: c.amazon.background,
  },
  1: {
    label: "Admin",
    value: 1,
    color: c.amazon.textColor,
    background: c.amazon.background,
  },
  2: {
    label: "Account Manager",
    value: 2,
    color: c.burntSienna.textColor,
    background: c.burntSienna.background,
  },
  3: {
    label: "Finance",
    value: 3,
    color: c.flickrPink.textColor,
    background: c.flickrPink.background,
  },
  4: {
    label: "Support",
    value: 4,
    color: c.amaranth.textColor,
    background: c.amaranth.background,
  },
  5: {
    label: "Advertiser",
    value: 5,
    color: c.rebeccaPurple.textColor,
    background: c.rebeccaPurple.background,
  },
  99: {
    label: "Banned",
    value: 99,
    color: c.englishRed.textColor,
    background: c.englishRed.background,
  },
};

export const enumUserType = {
  default: {
    label: "Internal",
    value: 1,
    color: c.starCommandBlue.textColor,
    background: c.starCommandBlue.background,
  },
  1: {
    label: "Internal",
    value: 1,
    color: c.starCommandBlue.textColor,
    background: c.starCommandBlue.background,
  },
  2: {
    label: "External",
    value: 2,
    color: c.royalPurple.textColor,
    background: c.royalPurple.background,
  },
};

export const enumUserPayment = {
  default: {
    label: "Inactive",
    value: false,
    color: c.englishRed.textColor,
    background: c.englishRed.background,
  },
  true: {
    label: "Active",
    value: true,
    color: c.forrestGreenCrayola.textColor,
    background: c.forrestGreenCrayola.background,
  },
  false: {
    label: "Inactive",
    value: false,
    color: c.englishRed.textColor,
    background: c.englishRed.background,
  },
}

export const enumLoginHistoryStatus = {
  default: {
    label: 'N/A',
    value: null,
    color: c.cadetGrey.textColor,
    background: c.cadetGrey.background,
  },
  1: {
    label: 'Success',
    value: 1,
    color: c.forrestGreenCrayola.textColor,
    background: c.forrestGreenCrayola.background,
  },
  2: {
    label: 'Error',
    value: 2,
    color: c.englishRed.textColor,
    background: c.englishRed.background,
  },
}

export const enumCampaignsModel = {
  default: {
    label: 'Native',
    value: 'native',
    color: c.amazon.textColor,
    background: c.amazon.background,
  },
  native: {
    label: 'Native',
    value: 'native',
    color: c.amazon.textColor,
    background: c.amazon.background,
  },
  push: {
    label: 'Push',
    value: 'push',
    color: c.burntSienna.textColor,
    background: c.burntSienna.background,
  },
  'in-page-push': {
    label: 'In Page Push',
    value: 'in-page-push',
    color: c.flickrPink.textColor,
    background: c.flickrPink.background,
  },
  'pop': {
    label: 'Pop',
    value: 'pop',
    color: c.amaranth.textColor,
    background: c.amaranth.background,
  },
  'smart': {
    label: 'Smart',
    value: 'smart',
    color: c.rebeccaPurple.textColor,
    background: c.rebeccaPurple.background,
  },
}

export const enumCampaignsStatusAfterApproved = {
  default: {
    label: 'Paused',
    value: 'paused',
    color: c.englishRed.textColor,
    background: c.englishRed.background,
  },
  active: {
    label: 'Active',
    value: 'active',
    color: c.forrestGreenCrayola.textColor,
    background: c.forrestGreenCrayola.background,
  },
  paused: {
    label: 'Paused',
    value: 'paused',
    color: c.englishRed.textColor,
    background: c.englishRed.background,
  },
}

export const enumCampaignsPricingModel = {
  default: {
    label: 'N/A',
    value: null,
    color: c.cadetGrey.textColor,
    background: c.cadetGrey.background,
  },
  cpc: {
    label: 'CPC',
    value: 'cpc',
    color: c.fuzzyWuzzy.textColor,
    background: c.fuzzyWuzzy.background,
  },
  cpm: {
    label: 'CPM',
    value: 'cpm',
    color: c.bdazzledBlue.textColor,
    background: c.bdazzledBlue.background,
  },
  cpa: {
    label: 'CPA',
    value: 'cpa',
    color: c.blueVioletCrayola.textColor,
    background: c.blueVioletCrayola.background,
  },
}

export const enumCampaignsSync = {
  default: {
    label: 'N/A',
    value: null,
    color: c.cadetGrey.textColor,
    background: c.cadetGrey.background,
  },
  'yes': {
    label: 'Yes',
    value: 'yes',
    color: c.forrestGreenCrayola.textColor,
    background: c.forrestGreenCrayola.background,
  },
  'no': {
    label: 'No',
    value: 'no',
    color: c.darkByzantium.textColor,
    background: c.darkByzantium.background,
  },
  'in_progress': {
    label: 'In progress',
    value: 'in_progress',
    color: c.royalPurple.textColor,
    background: c.royalPurple.background,
  },
}

export const enumCampaignsAction = {
  default: {
    label: 'N/A',
    value: null,
    color: c.cadetGrey.textColor,
    background: c.cadetGrey.background,
  },
  create: {
    label: 'Created',
    value: 'create',
    color: c.bdazzledBlue.textColor,
    background: c.bdazzledBlue.background,
  },
  update: {
    label: 'Update',
    value: 'update',
    color: c.bottleGreen.textColor,
    background: c.bottleGreen.background,
  },
  delete: {
    label: 'Delete',
    value: 'delete',
    color: c.brickRed.textColor,
    background: c.brickRed.background,
  },
}

export const enumCampaignsType = {
  default: {
    label: 'Internal',
    value: 1,
    color: c.starCommandBlue.textColor,
    background: c.starCommandBlue.background,
  },
  internal: {
    label: 'Internal',
    value: 'internal',
    color: c.starCommandBlue.textColor,
    background: c.starCommandBlue.background,
  },
  external: {
    label: 'External',
    value: 'external',
    color: c.royalPurple.textColor,
    background: c.royalPurple.background,
  },
}

export const enumCampaignsStatus = {
  default: {
    label: 'N/A',
    color: c.cadetGrey.textColor,
    background: c.cadetGrey.background,
  },
  active: {
    label: 'Active',
    value: 'active',
    color: c.forrestGreenCrayola.textColor,
    background: c.forrestGreenCrayola.background,
  },
  paused: {
    label: 'Paused',
    value: 'paused',
    color: c.englishRed.textColor,
    background: c.englishRed.background,
  },
  archive: {
    label: 'Archive',
    value: 'archive',
    color: c.bdazzledBlue.textColor,
    background: c.bdazzledBlue.background,
  },
  suspended: {
    label: 'Suspended',
    value: 'suspended',
    color: c.bottleGreen.textColor,
    background: c.bottleGreen.background,
  },
  disabled: {
    label: 'Disabled',
    value: 'disabled',
    color: c.brickRed.textColor,
    background: c.brickRed.background,
  },
  'hourly-limit': {
    label: 'Hourly Limit',
    value: 'hourly-limit',
    color: c.starCommandBlue.textColor,
    background: c.starCommandBlue.background,
  },
  'daily-limit': {
    label: 'Daily- Limit',
    value: 'daily-limit',
    color: c.darkByzantium.textColor,
    background: c.darkByzantium.background,
  },
  'monthly-limit': {
    label: 'Monthly Limit',
    value: 'monthly-limit',
    color: c.littleBoyBlue.textColor,
    background: c.littleBoyBlue.background,
  },
  implementing: {
    label: 'Implementing',
    value: 'implementing',
    color: c.olivine.textColor,
    background: c.olivine.background,
  },
  pending: {
    label: 'Pending',
    value: 'pending',
    color: c.paradisePink.textColor,
    background: c.paradisePink.background,
  },
  delete: {
    label: 'Delete',
    value: 'delete',
    color: c.rhythm.textColor,
    background: c.rhythm.background,
  },
}

export const enumAudienceOption = {
  default: {
    label: "Include",
    value: "include",
  },
  is: {
    label: "Include",
    value: "include",
  },
  is_not: {
    label: "Exclude",
    value: "exclude",
  },
};

export const enumOsOption = {
  default: {
    label: "EXACT",
    value: "exact",
  },
  exact: {
    label: "EXACT",
    value: "exact",
  },
  min_version: {
    label: "MIN VERSION",
    value: "min_version",
  },
};

export const enumAudienceCountry = {
  default: {
    label: "Bosnia and Herzegovina",
    value: "BA",
    image: "https://api.monadlead.com/storage/country/flags/32x32/ba.png",
  },
  ba: {
    label: "Bosnia and Herzegovina",
    value: "BA",
    image: "https://api.monadlead.com/storage/country/flags/32x32/ba.png",
  },
  rs: {
    label: "Serbia",
    value: "RS",
    image: "https://api.monadlead.com/storage/country/flags/32x32/rs.png",
  },
};

export const enumAudienceMonths = [
  {
    label: "January",
    value: 1,
  },
  {
    label: "February",
    value: 2,
  },
  {
    label: "March",
    value: 3,
  },
  {
    label: "April",
    value: 4,
  },
  {
    label: "May",
    value: 5,
  },
  {
    label: "June",
    value: 6,
  },
  {
    label: "July",
    value: 7,
  },
  {
    label: "August",
    value: 8,
  },
  {
    label: "September",
    value: 9,
  },
  {
    label: "October",
    value: 10,
  },
  {
    label: "November",
    value: 11,
  },
  {
    label: "December",
    value: 12,
  }
];

export const enumAudienceWeekday = [

  {
    label: "Monday",
    value: 1,
  },
  {
    label: "Tuesday",
    value: 2,
  },
  {
    label: "Wednesday",
    value: 3,
  },
  {
    label: "Thursday",
    value: 4,
  },
  {
    label: "Friday",
    value: 5,
  },
  {
    label: "Saturday",
    value: 6,
  }, {
    label: "Sunday",
    value: 7,
  }
]

export const enumAudienceMinutesFunction = () => {
  const enumAudienceMinutes = [];

  for (let i = 0; i <= 59; i++) {
    enumAudienceMinutes.push({
      label: i.toString(),
      value: i
    });
  }
  return enumAudienceMinutes;
}

export const enumAudienceHoursFunction = () => {
  const enumAudienceHours = [];
  for (let i = 0; i <= 23; i++) {
    enumAudienceHours.push({
      label: i.toString(),
      value: i
    })
  }
  return enumAudienceHours;
}

export const enumAudienceDaysFunction = () => {
  const enumAudienceDays = [];
  for (let i = 1; i <= 31; i++) {
    enumAudienceDays.push({
      label: i.toString(),
      value: i
    });
  }
  return enumAudienceDays;
}



export const enumTopUpsStatus = {
  "default": {
    label: 'N/A',
    value: null,
    color: c.cadetGrey.textColor,
    background: c.cadetGrey.background,
  },
  "created": {
    label: 'Created',
    value: 'created',
    color: c.bdazzledBlue.textColor,
    background: c.bdazzledBlue.background,
  },
  "paid": {
    label: 'Paid',
    value: 'paid',
    color: c.bottleGreen.textColor,
    background: c.bottleGreen.background,
  },
  "rejects": {
    label: 'Rejected',
    value: 'rejects',
    color: c.brickRed.textColor,
    background: c.brickRed.background,
  },
}