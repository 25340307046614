import { enumRoles, enumPermissions as permission } from "data/enums/authorization";
import { moduleNames } from "data/enums/modules";

const { ADMIN, PUBLISHER, ADVERTISER } = enumRoles

const allRoles = [ADMIN, PUBLISHER, ADVERTISER]
const allAdminRoles = [ADMIN]
const allPublisherRoles = [PUBLISHER]
const allAdvertiserRoles = [ADVERTISER]


const routes = [
  {
    id: 'admin',
    auth: {
      roles: allAdminRoles
    },
    modules: [
      {
        id: moduleNames.ADMIN_DASHBOARD_DROPDOWN, to: '/admin/dashboard',
        auth: { roles: allAdminRoles, permissions: [permission.ADMIN_REPORT_DYNAMICS] },
        routes: [
          {
            id: moduleNames.ADMIN_ADVERTISER_DASHBOARD, to: '/admin/dashboard/advertiser',
            auth: { roles: allAdminRoles, permissions: [permission.ADMIN_REPORT_DYNAMICS] },
          },
          {
            id: moduleNames.ADMIN_PUBLISHER_DASHBOARD, to: '/admin/dashboard/publisher',
            auth: { roles: allAdminRoles, permissions: [permission.ADMIN_DASHBOARD_PUBLISHER] },
          },
        ]
      },
      {
        id: moduleNames.ADMIN_REPORT_DROPDOWN, to: '/admin/report',
        auth: { roles: allAdminRoles, permissions: [permission.ADMIN_REPORT_DYNAMICS] },
        routes: [
          {
            id: moduleNames.ADMIN_REPORT_DYNAMICS, to: '/admin/report/dynamic',
            auth: { roles: allAdminRoles, permissions: [permission.ADMIN_REPORT_DYNAMICS] },
          },
        ]
      },
      // {
      //   id: moduleNames.ADMIN_OVERVIEW, to: '/admin/overview',
      //   auth: { roles: allAdminRoles, permissions: [permission.ADMIN_OVERVIEW_LIST] },
      // },
      {
        id: moduleNames.ADMIN_CAMPAIGNS_DROPDOWN, to: '/admin/campaigns',
        auth: { roles: allAdminRoles, permissions: [permission.ADMIN_CAMPAIGNS_LIST] },
        routes: [
          {
            id: moduleNames.ADMIN_CAMPAIGNS, to: '/admin/campaigns/list',
            auth: { roles: allAdminRoles, permissions: [permission.ADMIN_CAMPAIGNS_LIST] },
          },
          {
            id: moduleNames.ADMIN_UNAPPROVED_CAMPAIGNS, to: '/admin/campaigns/unapproved',
            auth: { roles: allAdminRoles, permissions: [permission.ADMIN_CAMPAIGNS_LIST] },
          },
        ]
      },

      {
        id: moduleNames.ADMIN_UNAPPROVED_CREATIVES, to: '/admin/unapproved-creatives',
        auth: { roles: allAdminRoles, permissions: [permission.ADMIN_CAMPAIGNS_CREATIVES_LIST] },
      },

      {
        id: moduleNames.ADMIN_USERS_DROPDOWN, to: '/admin/users',
        auth: { roles: allAdminRoles, permissions: [] },
        routes: [
          {
            id: moduleNames.ADMIN_USERS, to: '/admin/users/list',
            auth: { roles: allAdminRoles, permissions: [permission.ADMIN_USERS_LIST] },
          },
          {
            id: moduleNames.ADMIN_USERS_OVERVIEW, to: '/admin/users/overview',
            auth: { roles: allAdminRoles, permissions: [permission.ADMIN_USERS_OVERVIEW_TOTAL] },
          },
          {
            id: moduleNames.ADMIN_USERS_OVERDRAFTS, to: '/admin/users/overdrafts',
            auth: { roles: allAdminRoles, permissions: [permission.ADMIN_USERS_OVERDRAFT_LIST] },
          },
        ]
      },

      {
        id: moduleNames.ADMIN_CLICK_SETTINGS, to: '/admin/click-settings',
        auth: { roles: allAdminRoles, permissions: [permission.ADMIN_CLICK_SETTINGS_LIST] },
      },
      {
        id: moduleNames.ADMIN_SITES_DROPDOWN, to: '/admin/sites',
        auth: { roles: allAdminRoles, permissions: [] },
        routes: [
          {
            id: moduleNames.ADMIN_SITES, to: '/admin/sites/list',
            auth: { roles: allAdminRoles, permissions: [permission.ADMIN_SITES_LIST] },
          },
          {
            id: moduleNames.ADMIN_WIDGETS, to: '/admin/sites/widgets',
            auth: { roles: allAdminRoles, permissions: [permission.ADMIN_WIDGETS_LIST] },
          },
          {
            id: moduleNames.ADMIN_SITES_GROUPS, to: '/admin/sites/groups',
            auth: { roles: allAdminRoles, permissions: [permission.ADMIN_SITES_GROUPS_LIST] },
          },
          {
            id: moduleNames.ADMIN_SUBID, to: '/admin/sites/subid-settings',
            auth: { roles: allAdminRoles, permissions: [permission.ADMIN_SUBID_LIST] },
          },
        ]
      },

      {
        id: moduleNames.ADMIN_TOPUPS_DROPDOWN, to: '/admin/topups',
        auth: { roles: allAdminRoles, permissions: [] },
        routes: [
          {
            id: moduleNames.ADMIN_TOPUPS, to: '/admin/topups/list',
            auth: { roles: allAdminRoles, permissions: [permission.ADMIN_TOPUPS_LIST] },
          },
          {
            id: moduleNames.ADMIN_TOPUPS_COUPONS, to: '/admin/topups/coupons',
            auth: { roles: allAdminRoles, permissions: [permission.ADMIN_TOPUPS_COUPONS_LIST] },
          },
        ]
      },
      {
        id: moduleNames.ADMIN_BIDS_DROPDOWN, to: '/admin/bids',
        auth: { roles: allAdminRoles, permissions: [] },
        routes: [
          {
            id: moduleNames.ADMIN_BIDS, to: '/admin/bids/list',
            auth: { roles: allAdminRoles, permissions: [permission.ADMIN_BIDS_LIST] },
          },
          {
            id: moduleNames.ADMIN_COUNTRIES_BID_LIMITS, to: '/admin/bids/countries-bid-limits',
            auth: { roles: allAdminRoles, permissions: [permission.ADMIN_COUNTRIES_BID_LIMITS_LIST] },
          },
        ]
      },

      // {
      //   id: moduleNames.ADMIN_CLICK_LOG, to: '/admin/click-logs',
      //   auth: { roles: allAdminRoles, permissions: [permission.ADMIN_CLICK_LOG_LIST] },
      // },
      {
        id: moduleNames.ADMIN_SYSTEM_DROPDOWN, to: '/admin/system',
        auth: { roles: allAdminRoles, permissions: [] },
        routes: [
          {
            id: moduleNames.ADMIN_SYSTEM_EMAILS, to: '/admin/system/emails',
            auth: { roles: allAdminRoles, permissions: [permission.ADMIN_SYSTEM_EMAILS_LIST] },
          },
          {
            id: moduleNames.ADMIN_SYSTEM_NOTIFICATION, to: '/admin/system/notification',
            auth: { roles: allAdminRoles, permissions: [permission.ADMIN_SYSTEM_NOTIFICATION_LIST] },
          },
          {
            id: moduleNames.ADMIN_CHANGELOG, to: '/admin/change-log',
            auth: { roles: allAdminRoles, permissions: [permission.ADMIN_CHANGELOG_LIST] },
          },
        ]
      },
      {
        id: moduleNames.ADMIN_LOGS, to: '/admin/logs',
        auth: { roles: allAdminRoles, permissions: [] },
        routes: [
          {
            id: moduleNames.ADMIN_LOGS_QUEUE_EMAILS, to: '/admin/logs/queue-email',
            auth: { roles: allAdminRoles, permissions: [permission.ADMIN_LOGS_QUEUE_EMAILS] },
          },
          {
            id: moduleNames.ADMIN_LOGS_REQUESTS, to: '/admin/logs/request',
            auth: { roles: allAdminRoles, permissions: [permission.ADMIN_LOGS_REQUEST] },
          },
        ]
      },
      {
        id: moduleNames.ADMIN_LOGIN_HISTORY, to: '/admin/login-history',
        auth: { roles: allAdminRoles, permissions: [permission.ADMIN_LOGIN_HISTORY_LIST] },
      },

    ]
  },
  {
    id: 'advertiser',
    auth: {
      roles: allAdvertiserRoles
    },
    modules: [
      {
        id: moduleNames.ADVERTISER_DASHBOARD, to: '/advertiser/dashboard',
        auth: { roles: [ADVERTISER], permissions: [] },
      },
      {
        id: moduleNames.ADVERTISER_REPORT_DROPDOWN, to: '/advertiser/report',
        auth: { roles: [ADVERTISER], permissions: [] },
        routes: [
          {
            id: moduleNames.ADVERTISER_REPORT, to: '/advertiser/report/dynamic',
            auth: { roles: [ADVERTISER], permissions: [] },
          },

        ]
      },
      {
        id: moduleNames.ADVERTISER_CAMPAIGNS, to: '/advertiser/campaigns',
        auth: { roles: [ADVERTISER], permissions: [] },
      },
      {
        id: moduleNames.ADVERTISER_CREATIVES, to: '/advertiser/creatives',
        auth: { roles: [ADVERTISER], permissions: [] },
      },
      {
        id: moduleNames.ADVERTISER_TOPUPS, to: '/advertiser/topups',
        auth: { roles: [ADVERTISER], permissions: [] },
      },
      {
        id: moduleNames.ADVERTISER_LOGIN_HISTORY, to: '/advertiser/login-history',
        auth: { roles: [ADVERTISER], permissions: [] },
      },
      {
        id: moduleNames.ADVERTISER_REFERRAL, to: '/advertiser/referral',
        auth: { roles: [ADVERTISER], permissions: [] },
      },
    ]
  },
  {
    id: 'publisher',
    auth: {
      roles: allPublisherRoles
    },
    modules: [
      {
        id: moduleNames.PUBLISHER_DASHBOARD, to: '/publisher/dashboard',
        auth: { roles: [PUBLISHER], permissions: [] },
      },
      {
        id: moduleNames.PUBLISHER_REPORT_DROPDOWN, to: '/publisher/report',
        auth: { roles: [PUBLISHER], permissions: [] },
        routes: [
          {
            id: moduleNames.PUBLISHER_REPORT_DYNAMICS, to: '/publisher/report/dynamics',
            auth: { roles: [PUBLISHER], permissions: [] },
          },
          {
            id: moduleNames.PUBLISHER_REPORT_EXCHANGE_SENT, to: '/publisher/report/exchange-sent',
            auth: { roles: [PUBLISHER], permissions: [] },
          },
          {
            id: moduleNames.PUBLISHER_REPORT_EXCHANGE_RECEIVED, to: '/publisher/report/exchange-received',
            auth: { roles: [PUBLISHER], permissions: [] },
          }
        ]
      },
      {
        id: moduleNames.PUBLISHER_SITES, to: '/publisher/sites',
        auth: { roles: [PUBLISHER], permissions: [] },
      },
      {
        id: moduleNames.PUBLISHER_PAYMENT, to: '/publisher/payments',
        auth: { roles: [PUBLISHER], permissions: [] },
        routes: [
          {
            id: moduleNames.PUBLISHER_PAYMENTS_REQUEST, to: '/publisher/payments/request',
            auth: { roles: [PUBLISHER], permissions: [] },
          },
          {
            id: moduleNames.PUBLISHER_PAYMENTS_SETTINGS, to: '/publisher/payments/settings',
            auth: { roles: [PUBLISHER], permissions: [] },
          },
        ]
      },
      {
        id: moduleNames.PUBLISHER_EXCHANGE_SETTINGS, to: '/publisher/exchange-settings',
        auth: { roles: [PUBLISHER], permissions: [] },
      },
      {
        id: moduleNames.PUBLISHER_REFERRAL, to: '/publisher/referral',
        auth: { roles: [PUBLISHER], permissions: [] },
      },
      {
        id: moduleNames.PUBLISHER_LOGIN_HISTORY, to: '/publisher/login-history',
        auth: { roles: [PUBLISHER], permissions: [] },
      }
    ]
  },

  {
    id: 'system',
    auth: {
      roles: allRoles
    },
    modules: [
      {
        id: moduleNames.ADMIN_PROFILE, to: '/admin/profile',
        auth: { roles: allAdminRoles, presmissions: [] },
      },
      {
        id: moduleNames.PUBLISHER_PROFILE, to: '/publisher/profile',
        auth: { roles: [PUBLISHER], presmissions: [] },
      },
      {
        id: moduleNames.ADVERTISER_PROFILE, to: '/advertiser/profile',
        auth: { roles: [ADVERTISER], presmissions: [] },
      }
    ]
  },

]

if (process.env.NODE_ENV === 'development' && process.env.REACT_APP_STAGING !== 'true') {
  const devRoutes = {
    id: 'dev',
    auth: {
      roles: allRoles
    },
    modules: [
      {
        id: `${moduleNames.DEV_BUILDER_DROPDOWN}_admin`, to: '/admin/builder',
        auth: { roles: allAdminRoles, permissions: [] },
        routes: [
          {
            id: `${moduleNames.DEV_BUILDER_MODULE}_admin`, to: '/admin/builder/module',
            auth: { roles: allAdminRoles, permissions: [] },
          }
        ]
      },
      {
        id: `${moduleNames.DEV_BUILDER_DROPDOWN}_pub`, to: '/publisher/builder',
        auth: { roles: [PUBLISHER], permissions: [] },
        routes: [
          {
            id: `${moduleNames.DEV_BUILDER_MODULE}_pub`, to: '/publisher/builder/module',
            auth: { roles: [PUBLISHER], permissions: [] },
          }
        ]
      },
      {
        id: `${moduleNames.DEV_BUILDER_DROPDOWN}_adv`, to: '/advertiser/builder',
        auth: { roles: [ADVERTISER], permissions: [] },
        routes: [
          {
            id: `${moduleNames.DEV_BUILDER_MODULE}_adv`, to: '/advertiser/builder/module',
            auth: { roles: [ADVERTISER], permissions: [] },
          }
        ]
      },
    ]
  }
  routes.push(devRoutes)
}


export default routes