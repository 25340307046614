import { moduleNames } from "data/enums/modules";
import { enumPermissionModules as EPM, enumPermissions as EP } from "../enums/authorization";

const en = {
  MenuSegment: {
    admin: 'Admin',
    publisher: 'Publisher',
    advertiser: 'Advertiser',
    user: 'User',
    system: 'System',
    dev: 'Dev'
  },
  MenuLink: {
    [moduleNames.ADMIN_HOME]: 'Home',
    [moduleNames.ADMIN_DASHBOARD]: 'Dashboard',
    [moduleNames.ADMIN_DASHBOARD_DROPDOWN]: 'Dashboard',
    [moduleNames.ADMIN_LOGS]: 'Logs',
    [moduleNames.ADMIN_LOGS_QUEUE_EMAILS]: 'Queue Emails',
    [moduleNames.ADMIN_LOGS_REQUESTS]: 'Requests',
    [moduleNames.ADMIN_ADVERTISER_DASHBOARD]: 'Advertiser',
    [moduleNames.ADMIN_PUBLISHER_DASHBOARD]: 'Publisher',
    [moduleNames.ADMIN_CAMPAIGNS]: 'List',
    [moduleNames.ADMIN_UNAPPROVED_CAMPAIGNS]: 'Unapproved',
    [moduleNames.ADMIN_SITES]: 'Sites',
    [moduleNames.ADMIN_SITES_GROUPS]: 'Groups',
    [moduleNames.ADMIN_WIDGETS]: 'Widgets',
    [moduleNames.ADMIN_SUBID]: 'SubId Settings',
    [moduleNames.ADMIN_USERS_DROPDOWN]: 'Users',
    [moduleNames.ADMIN_USERS]: 'List',
    [moduleNames.ADMIN_USERS_OVERVIEW]: 'Overview',
    [moduleNames.ADMIN_BULK_SUSPEND]: 'Bulk suspend',
    [moduleNames.ADMIN_CLICK_LOG]: 'Click log',
    [moduleNames.ADMIN_STATISTIC]: 'Statistics',
    [moduleNames.ADMIN_LOGIN_HISTORY]: 'Login History',
    [moduleNames.ADMIN_CLICK_SETTINGS]: 'Click settings',
    [moduleNames.ADMIN_COUNTRIES_BID_LIMITS]: 'Countries Bid Limits',
    [moduleNames.ADMIN_PROFILE]: 'Profile',
    [moduleNames.ADMIN_BIDS]: 'List',
    [moduleNames.ADMIN_TOPUPS_DROPDOWN]: 'Topups',
    [moduleNames.ADMIN_TOPUPS]: 'List',
    [moduleNames.ADMIN_TOPUPS_COUPONS]: 'Coupons',
    [moduleNames.ADMIN_SITES_DROPDOWN]: 'Sites',
    [moduleNames.ADMIN_SYSTEM_DROPDOWN]: 'System',
    [moduleNames.ADMIN_SYSTEM_NOTIFICATION]: 'System notification',
    [moduleNames.ADMIN_OVERVIEW]: 'Overview',
    [moduleNames.ADMIN_USERS_OVERDRAFTS]: 'Overdrafts',
    [moduleNames.ADMIN_REPORT_DROPDOWN]: "Reports",
    [moduleNames.ADMIN_REPORT_DYNAMICS]: "Dynamics",
    [moduleNames.ADMIN_SYSTEM_EMAILS]: 'System emails',
    [moduleNames.ADMIN_UNAPPROVED_CREATIVES]: 'Unapproved creatives',
    [moduleNames.ADMIN_CAMPAIGNS_DROPDOWN]: 'Campaigns',
    [moduleNames.ADMIN_BIDS_DROPDOWN]: 'Bids',
    [moduleNames.ADMIN_CHANGELOG]: 'Change log',

    [`${moduleNames.DEV_BUILDER_DROPDOWN}_admin`]: 'Builder',
    [`${moduleNames.DEV_BUILDER_MODULE}_admin`]: 'Module',
    [`${moduleNames.DEV_BUILDER_DROPDOWN}_pub`]: 'Builder',
    [`${moduleNames.DEV_BUILDER_MODULE}_pub`]: 'Module',
    [`${moduleNames.DEV_BUILDER_DROPDOWN}_adv`]: 'Builder',
    [`${moduleNames.DEV_BUILDER_MODULE}_adv`]: 'Module',

    // PUBLISHER MODULES
    [moduleNames.PUBLISHER_DASHBOARD]: 'Dashboard',
    [moduleNames.PUBLISHER_SITES]: 'Sites',
    [moduleNames.PUBLISHER_STATISTIC]: 'Statistics',
    [moduleNames.PUBLISHER_PROFILE]: 'Profile',
    [moduleNames.PUBLISHER_PAYMENT]: 'Payments',
    [moduleNames.PUBLISHER_PAYMENTS_REQUEST]: 'List',
    [moduleNames.PUBLISHER_PAYMENTS_SETTINGS]: 'Settings',
    [moduleNames.PUBLISHER_EXCHANGE_SETTINGS]: 'Exchange settings',
    [moduleNames.PUBLISHER_REFERRAL]: 'Referal Program',
    [moduleNames.PUBLISHER_LOGIN_HISTORY]: 'Login history',
    [moduleNames.PUBLISHER_REPORT_DROPDOWN]: "Reports",
    [moduleNames.PUBLISHER_REPORT_EXCHANGE_RECEIVED]: "Exchange received",
    [moduleNames.PUBLISHER_REPORT_EXCHANGE_SENT]: "Exchange sent",
    [moduleNames.PUBLISHER_REPORT_DYNAMICS]: "Dynamics",


    // ADVERTISER MODULES
    [moduleNames.ADVERTISER_DASHBOARD]: 'Dashboard',
    [moduleNames.ADVERTISER_TOPUPS]: 'Topups',
    [moduleNames.ADVERTISER_CAMPAIGNS]: 'Campaigns',
    [moduleNames.ADVERTISER_CREATIVES]: 'Creatives',
    [moduleNames.ADVERTISER_LOGIN_HISTORY]: 'Login history',
    [moduleNames.ADVERTISER_REFERRAL]: 'Referal Program',
    [moduleNames.ADVERTISER_PROFILE]: 'Profile',
    [moduleNames.ADVERTISER_REPORT_DROPDOWN]: 'Reports',
    [moduleNames.ADVERTISER_REPORT]: 'Dynamic',


  },
  PermissionsModalBody: {
    admin: 'Admin',

    [EPM.ADMIN_DASHBOARD]: 'Dashboard',
    [EP.ADMIN_DASHBOARD_PUBLISHER]: 'Publisher',

    [EPM.ADMIN_LOGS]: 'Logs',
    [EP.ADMIN_LOGS_QUEUE_EMAILS]: 'Queue Emails',
    [EP.ADMIN_LOGS_REQUEST]: 'Requests',

    [EPM.ADMIN_USERS]: 'Users',
    [EP.ADMIN_USERS_LIST]: 'List',
    [EP.ADMIN_USERS_CREATE]: 'Create',
    [EP.ADMIN_USERS_UPDATE]: 'Update',
    [EP.ADMIN_USERS_DELETE]: 'Delete',

    [EPM.ADMIN_USERS_OVERDRAFT]: 'Users Overdrafts',
    [EP.ADMIN_USERS_OVERDRAFT_LIST]: 'List',
    [EP.ADMIN_USERS_OVERDRAFT_CREATE]: 'Create',
    [EP.ADMIN_USERS_OVERDRAFT_UPDATE]: 'Update',
    [EP.ADMIN_USERS_OVERDRAFT_DELETE]: 'Delete',

    [EPM.ADMIN_COUNTRIES_BID_LIMITS]: 'Countries Bid Limits',
    [EP.ADMIN_COUNTRIES_BID_LIMITS_LIST]: 'List',
    [EP.ADMIN_COUNTRIES_BID_LIMITS_CREATE]: 'Create',
    [EP.ADMIN_COUNTRIES_BID_LIMITS_UPDATE]: 'Update',
    [EP.ADMIN_COUNTRIES_BID_LIMITS_DELETE]: 'Delete',

    [EPM.ADMIN_CAMPAIGNS]: 'Campaigns',
    [EP.ADMIN_CAMPAIGNS_LIST]: 'List',
    [EP.ADMIN_CAMPAIGNS_CREATE]: 'Create',
    [EP.ADMIN_CAMPAIGNS_UPDATE]: 'Update',

    [EPM.ADMIN_REPORT]: 'Report',
    [EP.ADMIN_REPORT_DYNAMICS]: 'Dynamics',

    [EPM.ADMIN_SUBID]: 'SubId settings',
    [EP.ADMIN_SUBID_LIST]: 'List',
    [EP.ADMIN_SUBID_CREATE]: 'Create',
    [EP.ADMIN_SUBID_UPDATE]: 'Update',
    [EP.ADMIN_SUBID_DELETE]: 'Delete',

    [EPM.ADMIN_USERS_LIMITS]: 'Users limits',
    [EP.ADMIN_USERS_LIMITS_LIST]: 'List',
    [EP.ADMIN_USERS_LIMITS_CREATE]: 'Create',
    [EP.ADMIN_USERS_LIMITS_UPDATE]: 'Update',
    [EP.ADMIN_USERS_LIMITS_DELETE]: 'Delete',

    [EPM.ADMIN_PERMISSIONS]: 'Permission',
    [EP.ADMIN_PERMISSIONS_GET]: 'Get',
    [EP.ADMIN_PERMISSIONS_UPDATE]: 'Update',

    [EPM.ADMIN_FAST_LOGIN]: 'Fast login',
    [EP.ADMIN_FAST_LOGIN_CREATE]: 'Create',
    [EP.ADMIN_FAST_LOGIN_CHECK]: 'Check',

    [EPM.ADMIN_SITES]: 'Sites',
    [EP.ADMIN_SITES_LIST]: 'List',
    [EP.ADMIN_SITES_UPDATE]: 'Update',

    [EPM.ADMIN_CLICK_SETTINGS]: 'Click settings',
    [EP.ADMIN_CLICK_SETTINGS_LIST]: 'List',
    [EP.ADMIN_CLICK_SETTINGS_CREATE]: 'Create',

    [EPM.ADMIN_USERS_OVERVIEW]: 'Users Overview',
    [EP.ADMIN_USERS_OVERVIEW_LIST]: 'List',
    [EP.ADMIN_USERS_OVERVIEW_TOTAL]: 'Total',

    [EPM.ADMIN_CLICK_LOG]: 'Click Log',
    [EP.ADMIN_CLICK_LOG_LIST]: 'List',

    [EPM.ADMIN_STATISTIC]: 'Statistics',
    [EP.ADMIN_STATISTIC_LIST]: 'List',

    [EPM.ADMIN_WIDGETS]: 'Widgets',
    [EP.ADMIN_WIDGETS_LIST]: 'List',
    [EP.ADMIN_WIDGETS_UPDATE]: 'Update',
    [EP.ADMIN_WIDGETS_DELETE]: 'Delete',

    [EPM.ADMIN_SITES_GROUPS]: 'Sites groups',
    [EP.ADMIN_SITES_GROUPS_LIST]: 'List',
    [EP.ADMIN_SITES_GROUPS_CREATE]: 'Create',
    [EP.ADMIN_SITES_GROUPS_UPDATE]: 'Update',
    [EP.ADMIN_SITES_GROUPS_DELETE]: 'Delete',
    [EP.ADMIN_SITES_GROUPS_ASSIGN]: 'Assign',
    [EP.ADMIN_SITES_GROUPS_UNASSIGN]: 'Unassign',
    [EP.ADMIN_SITES_GROUPS_LIST_ASSIGNED]: 'List Assigned',
    [EP.ADMIN_SITES_GROUPS_LIST_NOT_ASSIGNED]: 'List Not Assigned',

    [EPM.ADMIN_BIDS]: 'Bids',
    [EP.ADMIN_BIDS_LIST]: 'List',
    [EP.ADMIN_BIDS_CREATE]: 'Create',
    [EP.ADMIN_BIDS_UPDATE]: 'Update',
    [EP.ADMIN_BIDS_DELETE]: 'Delete',

    [EPM.ADMIN_TOPUPS]: 'Topups',
    [EP.ADMIN_TOPUPS_LIST]: 'List',
    [EP.ADMIN_TOPUPS_CREATE]: 'Create',
    [EP.ADMIN_TOPUPS_UPDATE]: 'Update',
    [EP.ADMIN_TOPUPS_DELETE]: 'Delete',

    [EPM.ADMIN_TOPUPS_COUPONS]: 'Topups Coupons',
    [EP.ADMIN_TOPUPS_COUPONS_LIST]: 'List',
    [EP.ADMIN_TOPUPS_COUPONS_CREATE]: 'Create',
    [EP.ADMIN_TOPUPS_COUPONS_UPDATE]: 'Update',
    [EP.ADMIN_TOPUPS_COUPONS_DELETE]: 'Delete',

    [EPM.ADMIN_LOGIN_HISTORY]: 'Login History',
    [EP.ADMIN_LOGIN_HISTORY_LIST]: 'List',

    [EPM.ADMIN_SYSTEM_NOTIFICATION]: 'System notification',
    [EP.ADMIN_SYSTEM_NOTIFICATION_LIST]: 'List',
    [EP.ADMIN_SYSTEM_NOTIFICATION_CREATE]: 'Create',
    [EP.ADMIN_SYSTEM_NOTIFICATION_DELETE]: 'Delete',
    [EP.ADMIN_SYSTEM_NOTIFICATION_UPDATE]: 'Update',

    [EPM.ADMIN_OVERVIEW]: 'Overview',
    [EP.ADMIN_OVERVIEW_LIST]: 'List',
    [EP.ADMIN_OVERVIEW_TOTAL]: 'Total',

    [EPM.ADMIN_CAMPAIGNS_LIMITS]: 'Campaign - Limits',
    [EP.ADMIN_CAMPAIGNS_LIMITS_CREATE]: 'Create',
    [EP.ADMIN_CAMPAIGNS_LIMITS_LIST]: 'List',
    [EP.ADMIN_CAMPAIGNS_LIMITS_DELETE]: 'Delete',

    [EPM.ADMIN_CAMPAIGNS_CREATIVES]: 'Campaign - Creatives',
    [EP.ADMIN_CAMPAIGNS_CREATIVES_CREATE]: 'Create',
    [EP.ADMIN_CAMPAIGNS_CREATIVES_LIST]: 'List',
    [EP.ADMIN_CAMPAIGNS_CREATIVES_UPDATE]: 'Update',

    [EPM.ADMIN_CAMPAIGNS_AUDIENCE]: 'Campaign - Audience',
    [EP.ADMIN_CAMPAIGNS_AUDIENCE_GET]: 'Get',
    [EP.ADMIN_CAMPAIGNS_AUDIENCE_UPDATE]: 'Update',

    [EPM.ADMIN_CAMPAIGNS_PRICING]: 'Campaign - Pricing',
    [EP.ADMIN_CAMPAIGNS_PRICING_UPDATE]: 'Update',
    [EP.ADMIN_CAMPAIGNS_PRICING_DELETE]: 'Delete',
    [EP.ADMIN_CAMPAIGNS_PRICING_CREATE]: 'Create',
    [EP.ADMIN_CAMPAIGNS_PRICING_LIST]: 'List',

    [EPM.ADMIN_SYSTEM_EMAILS]: 'System emails',
    [EP.ADMIN_SYSTEM_EMAILS_LIST]: 'List',
    [EP.ADMIN_SYSTEM_EMAILS_CREATE]: 'Create',
    [EP.ADMIN_SYSTEM_EMAILS_UPDATE]: 'Update',
    [EP.ADMIN_SYSTEM_EMAILS_DELETE]: 'Delete',
    [EP.ADMIN_SYSTEM_EMAILS_SEND_TEST]: 'Send test',

    [EPM.ADMIN_CHANGELOG]: "Change log",
    [EP.ADMIN_CHANGELOG_LIST]: "List",
    [EP.ADMIN_CHANGELOG_UPDATE]: "Update",
    [EP.ADMIN_CHANGELOG_CREATE]: "Create",
    [EP.ADMIN_CHANGELOG_DELETE]: "Delete",


    common: 'Common',
    [EPM.SETTINGS]: 'Settings',
    [EP.SETTINGS_GET]: 'Get',
    [EP.SETTINGS_UPDATE]: 'Update',

  },
  DashboardFooter: {
    companyName: 'MonadPlug',
    craftedLeft: 'Crafted with',
    craftedRight: 'by MONETIZEAD'
  },
  Logout: {
    title: 'Logout',
    loading: 'Waiting to safely log out',
    completed: 'You have been safely logged out',
    button: 'Back to Login'
  },
  RequestErrors: {
    default: 'Unknown error',
    browser: {
      ERR_NETWORK: 'Network error'
    },
    network: {
      401: 'Unauthenticated user',
      403: 'Insufficient permissions',
      405: 'Method not allowed',
      406: 'Request not acceptable'
    },
    internal: {
      0: "Unknown error.",
      1: "The requested method is not allowed.",
      2: "Login information on the system has not been forwarded.",
      3: "Username is not in the correct format.",
      4: "Password is not in the correct format.",
      5: "Login data is invalid.",
      6: "Database error.",
      7: "POST request is not in the correct format.",
      8: "Input is not valid.",
      9: "Input already exists in the database.",
      10: "ID must be passed through the query parameter.",
      11: "The requested ID was not found in the database.",
      12: "One of the search parameters (e.g., ID) must be passed.",
      13: "Query parameter is not in the correct format.",
      14: "The requested user was not found in the database.",
      15: "The requested country was not found in the database.",
      16: "User does not have permission for the requested module/action.",
      17: "MySQL table name must be passed.",
      18: "Value not found in the selected table.",
      19: "Error passing key/value parameters.",
      20: "File format is not allowed.",
      21: "Category was not found in the database.",
      23: "Product ID was not passed.",
      25: "Selected table is not allowed to be viewed using this method.",
      26: "Unable to find the user. General error.",
      28: "No users for payout in the requested period.",
      29: "Only pending payout is allowed to be deleted.",
      30: "Parameter 'status' must be passed.",
      31: "Unable to switch from the existing to the requested status.",
      33: "Entry has already been received in the system.",
      34: "Status must be passed through the query.",
      36: "User does not have an active selected payment method.",
      37: "The amount entered is not sufficient for the selected method.",
      38: "The selected method is currently unavailable for use.",
      39: "The selected method is currently inactive on your account.",
      40: "You currently have insufficient funds in your account.",
      41: "Username must be passed through the query parameter.",
      42: "Unable to find the user in the database.",
      43: "A link to reset the password has already been requested for the user. Check your spam/junk.",
      44: "Token cannot be found.",
      45: "You need to re-enter the password correctly.",
      46: "Error in communication with an external service.",
      50: "Input length exceeds the allowed limit.",
      52: "Payment method is not active.",
      53: "Invoice format is invalid.",
      60: "User ID needs to be sent.",
      61: "User needs to have an account manager assigned in order to be approved.",
      62: "Account already confirmed.",
      63: "You are not assigned as a manager for this user.",
      64: "Maximum number of registrations reached from the same IP address.",
      65: "User cannot be deleted from the system.",
      66: "Passed status is not valid in the system.",
      67: "Passed rank is not valid in the system.",
      68: "Reason for banning the user must be passed.",
      69: "Parameter to be changed must be passed.",
      70: "Token parameter must be passed through the query.",
      71: "Token not found in the table.",
      72: "Email must be passed through the query parameter.",
      73: "You have reached the maximum limit for creating new sites.",
      74: "Verification failed.",
      75: "Site ID is not valid, possibly the site is not yet approved.",
      76: "User has reached the maximum number of widgets.",
      77: "User has reached the maximum number of widgets for this format on this site.",
      78: "Ad Format is currently not active.",
      79: "You need to confirm your account via email in order to add a site.",
      80: "You need to wait for us to verify your account. We will notify you via email when your account is ready to use.",
      81: "User is banned, password reset is not possible.",
      82: "You can request a new password change every 5 minutes.",
      83: "Monetization mode is not active",
      84: "Rule for this subid is already created.",
      85: "Cannot delete the group to which sites are added",
      86: "You can request a new account verification email every 2 minutes",
      87: "The user has already verified the email",
      88: "The current method is not active.",
      89: "The payment amount is less than the allowed limit.",
      90: "The amount exceeds the allowed limit.",
      91: "The coupon is not valid.",
      92: "The coupon has been used the maximum number of times",
      93: "The user has used the maximum number of entries for the same coupon",
      94: "The billing model is currently inactive, it's not possible to create a campaign in this model.",
      95: "The minimum price for a campaign is $1",
      96: "It's not possible to create a creative for a campaign that is suspended or archived",
      97: "A limit with the same settings has already been created, please delete or edit",
      98: "Payments with these settings have already been saved",
      99: "It's not possible to reduce the budget below the current campaign spend.",
      100: "The campaign is in a status that restricts its editing.",
      101: "The campaign is in a status that restricts its manual movement to active",
      102: "The creative is in a status that restricts its editing",
      103: "It's not possible to duplicate a campaign that is suspended",
      104: "System error, the basic billing method for the ad does not exist.",
      105: "Total Budget exceeds the maximum defined per campaign.",
      106: "BID is not active or does not belong to the campaign's pricing model.",
      107: "The maximum number of creatives for the campaign has been created.",
      108: "The maximum number of creatives for the user has been created.",
      109: "Only one draft per user allowed",
      110: "The date range exceeds the maximum allowed.",
      111: "The email sending service is not enabled. Please contact the administrator.",
      112: "The AI service is not active. Please contact the administrator."

    },
    custom: {}
  },

  [moduleNames.ADMIN_LOGIN_HISTORY]: {
    browserTabTitle: 'MonadPlug | Login history',
  },

  [moduleNames.ADMIN_DASHBOARD]: {
    browserTabTitle: 'MonadPlug | Dashboard',
  },

  [moduleNames.ADMIN_CAMPAIGNS]: {
    browserTabTitle: 'MonadPlug | Campaigns',
    createLabel: 'Add campaign',
    editLabel: 'Edit',
    archiveLabel: 'Archive',
    archiveBulkLabel: 'Archive',
    archiveModalTitle: 'Archive campaign',
    archiveModalBulkTitle: 'Archive campaigns',
    editBulkLabel: 'Edit',
    deleteBulkLabel: 'Delete',
    createModalTitle: 'Create campaign',
    editModalTitle: 'Edit campaign',
    deleteModalTitle: 'Delete campaign',
    deleteModalBulkTitle: 'Delete campaigns',
    editModalBulkTitle: 'Edit campaigns',
    budgetLabel: 'Increase budget',
    bidLabel: 'Bids',
    bidModalTitle: 'Edit bid for',
    budgetModalTitle: 'Edit budget for',
    pauseCampaignLabel: 'Pause campaign',
    activateCampaignLabel: 'Activate campaign',
    activateBulkLabel: 'Activate',
    copyModalTitle: 'Duplicate campaign',
    activateModalBulkTitle: 'Activate campaigns',
    duplicateLabel: 'Duplicate campaign',
    pauseBulkLabel: 'Pause',
    pauseModalBulkTitle: 'Pause campaigns',
    reasonModalTitle: 'Add reason for unapproved campaign:',
    unapproveModalTitle: 'Unapprove campaign',
    pauseTitle: 'Pause campaign',
    pauseConfirmationText: 'Are you sure you want to pause this campaign?',
    activationModalText: 'Are you sure you want to activate campaign?',
    activationModalTitle: 'Activate campaign',
    approveModalText: 'Are you sure you want to approve this campaign?',
    approveCampaignTitle: 'Approve campaign',
    archiveModalTitle: 'Archive campaign',
    archiveModalText: 'Are you sure you want to archive this campaign?',
    viewLabel: 'View campaign',
    approveLabel: 'Approve',
    unapproveLabel: 'Unapprove',
    reasonLabel: 'Reason for unapproved campaign',
    suspendLabel: 'Suspend',
    suspendModalTitle: 'Suspend campaign',

  },

  [moduleNames.ADMIN_USERS]: {
    browserTabTitle: 'MonadPlug | Users',
    createLabel: 'Add user',
    editLabel: 'Edit',
    authLabel: 'Auth',
    permissionsLabel: 'Permissions',
    deleteLabel: 'Delete',
    editBulkLabel: 'Edit',
    deleteBulkLabel: 'Delete',
    createModalTitle: 'Create user',
    editModalTitle: 'Edit user',
    authModalTitle: 'Edit user password',
    permissionModalTitle: 'User permissions',
    deleteModalTitle: 'Delete user',
    deleteModalBulkTitle: 'Delete users',
    editModalBulkTitle: 'Edit users',
    banUser: 'Ban user',
    banModalTitle: 'Ban user',
  },

  [moduleNames.ADMIN_USERS_OVERDRAFTS]: {
    browserTabTitle: 'MonadPlug | Users Overdrafts',
    createLabel: 'Add overdraft',
    editLabel: 'Edit',
    deleteLabel: 'Delete',
    editBulkLabel: 'Edit',
    deleteBulkLabel: 'Delete',
    createModalTitle: 'Create overdraft',
    editModalTitle: 'Edit overdraft',
    deleteModalTitle: 'Delete overdraft',
    deleteModalBulkTitle: 'Delete overdrafts',
    editModalBulkTitle: 'Edit overdrafts',
  },

  [moduleNames.ADMIN_COUNTRIES_BID_LIMITS]: {
    browserTabTitle: 'MonadPlug | Countries Bid Limits',
    createLabel: 'Add',
    editLabel: 'Edit',
    deleteLabel: 'Delete',
    editBulkLabel: 'Edit',
    deleteBulkLabel: 'Delete',
    createModalTitle: 'Create limit',
    editModalTitle: 'Edit limit',
    deleteModalTitle: 'Delete limit',
    deleteModalBulkTitle: 'Delete limits',
    editModalBulkTitle: 'Edit limits',
  },

  [moduleNames.ADMIN_CHANGELOG]: {
    browserTabTitle: 'MonadPlug - Change log',
    createLabel: 'Add log',
    editLabel: 'Edit',
    deleteLabel: 'Delete',
    editBulkLabel: 'Edit',
    addModalTitle: 'Create',
    editModalTitle: 'Edit log',
    editModalBulkTitle: 'Edit logs',
    deleteBulkLabel: 'Delete',
    deleteModalTitle: 'Delete log',
    deleteModalBulkTitle: 'Delete logs'
  },

  [moduleNames.ADMIN_BIDS]: {
    browserTabTitle: 'MonadPlug | Bids',
    createLabel: 'Add bid',
    editLabel: 'Edit',
    deleteLabel: 'Delete',
    editBulkLabel: 'Edit',
    deleteBulkLabel: 'Delete',
    createModalTitle: 'Create bid',
    editModalTitle: 'Edit bid',
    deleteModalTitle: 'Delete bid',
    deleteModalBulkTitle: 'Delete bids',
    editModalBulkTitle: 'Edit bids',
  },

  [moduleNames.ADMIN_TOPUPS]: {
    browserTabTitle: 'MonadPlug | Topups',
    createLabel: 'Add topup',
    editLabel: 'Edit',
    refund: 'Refund',
    deleteLabel: 'Delete',
    editBulkLabel: 'Edit',
    deleteBulkLabel: 'Delete',
    createModalTitle: 'Create topup',
    editModalTitle: 'Edit topup',
    deleteModalTitle: 'Delete topup',
    deleteModalBulkTitle: 'Delete topups',
    editModalBulkTitle: 'Edit topups'
  },


  [moduleNames.ADMIN_TOPUPS_COUPONS]: {
    browserTabTitle: 'MonadPlug | Topups Coupons',
    createLabel: 'Add coupon',
    editLabel: 'Edit',
    refundLabel: 'Refund',
    deleteLabel: 'Delete',
    editBulkLabel: 'Edit',
    deleteBulkLabel: 'Delete',
    createModalTitle: 'Create coupon',
    editModalTitle: 'Edit coupon',
    deleteModalTitle: 'Delete coupon',
    deleteModalBulkTitle: 'Delete coupons',
    editModalBulkTitle: 'Edit coupons',
  },

  [moduleNames.ADMIN_DASHBOARD]: {
    browserTabTitle: 'MonadPlug | Sites',
  },

  [moduleNames.ADMIN_LOGS]: {
    browserTabTitle: 'MonadPlug | Logs'
  },

  [moduleNames.ADMIN_LOGS_QUEUE_EMAILS]: {
    browserTabTitle: 'MonadPlug | Queue Emails'
  },

  [moduleNames.ADMIN_LOGS_REQUESTS]: {
    browserTabTitle: 'MonadPlug | Requests'
  },

  [moduleNames.ADMIN_REPORT_DYNAMICS]: {
    browserTabTitle: 'MonadPlug | Report Dynamics',
  },

  [moduleNames.ADMIN_USERS_OVERVIEW]: {
    browserTabTitle: 'MonadPlug | Users Overview',
  },

  [moduleNames.ADMIN_CLICK_LOG]: {
    browserTabTitle: 'MonadPlug | Click Log',
  },

  [moduleNames.ADMIN_STATISTIC]: {
    browserTabTitle: 'MonadPlug | Statistic',
  },

  [moduleNames.ADMIN_CLICK_SETTINGS]: {
    browserTabTitle: 'MonadPlug | Click Settings',
  },

  [moduleNames.ADMIN_SITES_GROUPS]: {
    browserTabTitle: 'MonadPlug | Sites Groups',
    createLabel: 'Add',
    editLabel: 'Edit',
    deleteLabel: 'Delete',
    editBulkLabel: 'Edit',
    deleteBulkLabel: 'Delete',
    createModalTitle: 'Create group',
    editModalTitle: 'Edit group',
    deleteModalTitle: 'Delete group',
    deleteModalBulkTitle: 'Delete groups',
    editModalBulkTitle: 'Edit groups',
  },

  [moduleNames.ADMIN_SYSTEM_EMAILS]: {
    browserTabTitle: 'MonadPlug | Emails',
    createLabel: 'Add',
    editLabel: 'Edit',
    deleteLabel: 'Delete',
    editBulkLabel: 'Edit',
    deleteBulkLabel: 'Delete',
    createModalTitle: 'Create email',
    editModalTitle: 'Edit email',
    deleteModalTitle: 'Delete email',
    deleteModalBulkTitle: 'Delete emails',
    editModalBulkTitle: 'Edit emails',
    sendTestTitle: 'Send test email',
    sendTestLabel: 'Send test',
    editTemplate: 'Upload new template',
    editTemplateTitle: 'Upload new template to replace current for',
    previewTemplate: 'Preview template'

  },

  [moduleNames.ADMIN_WIDGETS]: {
    browserTabTitle: 'MonadPlug | Widgets',
    createLabel: 'Add widget',
    editLabel: 'Edit',
    authLabel: 'Auth',
    permissionsLabel: 'Permissions',
    deleteLabel: 'Delete',
    editBulkLabel: 'Edit',
    deleteBulkLabel: 'Delete',
    createModalTitle: 'Create widget',
    editModalTitle: 'Edit widget',
    authModalTitle: 'Edit widget password',
    permissionModalTitle: 'widget permissions',
    deleteModalTitle: 'Delete widget',
    deleteModalBulkTitle: 'Delete widgets',
    editModalBulkTitle: 'Edit widgets',
  },


  [moduleNames.ADMIN_SITES]: {
    browserTabTitle: 'MonadPlug | Sites',
    createLabel: 'Add site',
    editLabel: 'Edit',
    authLabel: 'Auth',
    permissionsLabel: 'Permissions',
    deleteLabel: 'Delete',
    editBulkLabel: 'Edit',
    deleteBulkLabel: 'Delete',
    createModalTitle: 'Create site',
    editModalTitle: 'Edit site',
    authModalTitle: 'Edit site password',
    permissionModalTitle: 'site permissions',
    deleteModalTitle: 'Delete site',
    deleteModalBulkTitle: 'Delete sites',
    editModalBulkTitle: 'Edit sites',
    rejectSiteModalTitle: 'Reject site',
    rejectSiteModalTitle: 'Suspend site',
    confirmationModalBodyMessage: 'Are you sure you want to activate this site?',
    confirmationModalTitle: 'Activate site',
  },

  [moduleNames.ADMIN_PROFILE]: {
    browserTabTitle: 'MonadPlug - Profile',
  },

  [moduleNames.ADMIN_SYSTEM_NOTIFICATION]: {
    browserTabTitle: 'MonadPlug - System notification',
  },

  [moduleNames.ADMIN_OVERVIEW]: {
    browserTabTitle: 'MonadPlug | Overview',
  },

  // publisher

  [moduleNames.PUBLISHER_LOGIN_HISTORY]: {
    browserTabTitle: 'MonadPlug | Login history',
  },

  [moduleNames.PUBLISHER_DASHBOARD]: {
    browserTabTitle: 'MonadPlug | Dashboard',
  },
  [moduleNames.PUBLISHER_SITES]: {
    browserTabTitle: 'MonadPlug | Sites',
    createLabel: 'Add site',
    editLabel: 'Edit',
    deleteLabel: 'Delete',
    createModalTitle: 'Create site',
    editModalTitle: 'Edit site',
    deleteModalTitle: 'Delete site',
    deleteModalBulkTitle: 'Delete sites',
    editModalBulkTitle: 'Edit sites',
    addModalTitle: 'Create site',
    editBulkLabel: 'Edit',
    deleteBulkLabel: 'Delete',
  },
  [moduleNames.PUBLISHER_STATISTIC]: {
    browserTabTitle: 'MonadPlug | Statistic',
  },

  [moduleNames.PUBLISHER_EXCHANGE_SETTINGS]: {
    browserTabTitle: 'MonadPlug | Exchange Settings',
  },

  [moduleNames.PUBLISHER_PROFILE]: {
    browserTabTitle: 'MonadPlug | Profile',
  },
  [moduleNames.PUBLISHER_REPORT_DYNAMICS]: {
    browserTabTitle: 'MonadPlug | Dynamics',
  },
  [moduleNames.PUBLISHER_REPORT_EXCHANGE_RECEIVED]: {
    browserTabTitle: 'MonadPlug | Exchange Received',
  },
  [moduleNames.PUBLISHER_REPORT_EXCHANGE_SENT]: {
    browserTabTitle: 'MonadPlug | Exchange Sent',
  },
  [moduleNames.PUBLISHER_PAYMENT]: {
    browserTabTitle: 'MonadPlug | Payment',
    title: 'Existing payment methods',
    createLabel: 'Add new payment method',
    editLabel: 'Edit',
    addModalTitle: 'Create payment settings',
    editModalTitle: 'Edit payment settings',
    deleteModalTitle: 'Delete',
    activateModalTitle: 'Activate payment method',
    activateModalBody: 'This action wil set payment method to status active',
    deactivateModalTitle: 'Deactivate payment method',
    deactivateModalBody: 'This action wil set payment method to status inactive',
  },
  [moduleNames.PUBLISHER_PAYMENTS_SETTINGS]: {
    browserTabTitle: 'MonadPlug - Payments settings',
    title: 'Existing payment methods',
    createLabel: 'Add new payment method',
    editLabel: 'Edit',
    addModalTitle: 'Create payment settings',
    editModalTitle: 'Edit payment settings',
    deleteModalTitle: 'Delete',
    activateModalTitle: 'Activate payment method',
    activateModalBody: 'This action wil set payment method to status active',
    deactivateModalTitle: 'Deactivate payment method',
    deactivateModalBody: 'This action wil set payment method to status inactive',
  },
  [moduleNames.PUBLISHER_REFERRAL]: {
    browserTabTitle: 'MonadPlug | Referral',
  },

  [moduleNames.PUBLISHER_CUSTOMIZE_WIDGET]: {
    browserTabTitle: 'MonadPlug | Customize Widgets',
  },

  // Advertiser

  [moduleNames.ADVERTISER_DASHBOARD]: {
    browserTabTitle: 'MonadPlug | Dashboard',
  },


  [moduleNames.ADVERTISER_TOPUPS]: {
    browserTabTitle: 'MonadPlug | Topups',
    createLabel: 'Add topup',
    editLabel: 'Edit',
    refund: 'Refund',
    deleteLabel: 'Delete',
    editBulkLabel: 'Edit',
    deleteBulkLabel: 'Delete',
    createModalTitle: 'Create topup',
    editModalTitle: 'Edit topup',
    deleteModalTitle: 'Delete topup',
    deleteModalBulkTitle: 'Delete topups',
    editModalBulkTitle: 'Edit topups',
    createRegularLabel: 'Credit card',
    createWithCouponLabel: 'Use a coupon',
    createWithCouponTitle: 'Enter the code below',

  },

  [moduleNames.ADVERTISER_LOGIN_HISTORY]: {
    browserTabTitle: 'MonadPlug | Login history',
  },

  [moduleNames.ADVERTISER_REFERRAL]: {
    browserTabTitle: 'MonadPlug | Referral',
  },

  [moduleNames.ADVERTISER_PROFILE]: {
    browserTabTitle: 'MonadPlug | Profile',
  },

  [moduleNames.ADVERTISER_REPORT]: {
    browserTabTitle: 'MonadPlug | Report',
  },

  [moduleNames.ADVERTISER_CREATIVES]: {
    browserTabTitle: 'MonadPlug | Creatives',
    createLabel: 'Add creative',
    editLabel: 'Edit',
    deleteLabel: 'Delete',
    editBulkLabel: 'Edit',
    deleteBulkLabel: 'Delete',
    createModalTitle: 'Create creative',
    editModalTitle: 'Edit creative',
    deleteModalTitle: 'Delete creative',
    deleteModalBulkTitle: 'Delete creatives',
    editModalBulkTitle: 'Edit creatives',
  },

  [moduleNames.ADVERTISER_CAMPAIGNS]: {
    browserTabTitle: 'MonadPlug | Campaigns',
    createLabel: 'Add campaign',
    editLabel: 'Edit',
    deleteLabel: 'Delete',
    editBulkLabel: 'Edit',
    deleteBulkLabel: 'Delete',
    createModalTitle: 'Create campaign',
    editModalTitle: 'Edit campaign',
    deleteModalTitle: 'Delete campaign',
    deleteModalBulkTitle: 'Delete campaigns',
    editModalBulkTitle: 'Edit campaigns',
    budgetLabel: 'Increase budget',
    bidLabel: 'Bids',
    bidModalTitle: 'Edit bid for',
    budgetModalTitle: 'Edit budget for',
    pauseCampaignLabel: 'Pause campaign',
    activateCampaignLabel: 'Activate campaign',
    activateBulkLabel: 'Activate',
    activateModalBulkTitle: 'Activate campaigns',
    pauseBulkLabel: 'Pause',
    pauseModalBulkTitle: 'Pause campaigns',
    copyModalTitle: 'Duplicate campaign',
    duplicateLabel: 'Duplicate',
  },
}

export default en;