export const moduleNames = {

  // ADMIN MODULES
  ADMIN_HOME: 'adminHome',
  ADMIN_DASHBOARD: 'adminDashboard',
  ADMIN_DASHBOARD_DROPDOWN: 'adminDashboardDropdown',
  ADMIN_ADVERTISER_DASHBOARD: 'adminAdvertiserDashboard',
  ADMIN_PUBLISHER_DASHBOARD: 'adminPublisherDashboard',
  ADMIN_LOGS: 'adminLogs',
  ADMIN_LOGS_QUEUE_EMAILS: 'adminQueueEmails',
  ADMIN_LOGS_REQUESTS: 'adminRequests',
  ADMIN_SUBID: 'adminSubid',
  ADMIN_USERS_DROPDOWN: 'adminUsersDropdown',
  ADMIN_USERS: 'adminUsers',
  ADMIN_SITES: 'adminSites',
  ADMIN_SITES_GROUPS: 'adminSitesGroups',
  ADMIN_ASSIGNED_GROUPS: 'adminAssignedGroups',
  ADMIN_UNASSIGNED_GROUPS: 'adminUnassignedGroups',
  ADMIN_USERS_OVERVIEW: 'adminUsersOverview',
  ADMIN_STATISTIC: 'adminStatistic',
  ADMIN_BULK_SUSPEND: 'adminBulkSuspend',
  ADMIN_CLICK_LOG: 'adminClickLog',
  ADMIN_WIDGETS: 'adminWidgets',
  ADMIN_LOGIN_HISTORY: 'adminLoginHistory',
  ADMIN_PROFILE: 'adminProfile',
  ADMIN_SYSTEM_NOTIFICATION: 'adminSystemNotification',
  ADMIN_OVERVIEW: 'adminOverview',
  ADMIN_PROFILE: 'profile',
  ADMIN_CLICK_SETTINGS: 'adminClickSettings',
  ADMIN_BIDS: 'adminBids',
  ADMIN_TOPUPS: 'adminTopups',
  ADMIN_TOPUPS_COUPONS: 'adminTopupsCoupons',
  ADMIN_CAMPAIGNS: 'adminCampaigns',
  ADMIN_UNAPPROVED_CAMPAIGNS: 'adminUnapprovedCampaigns',
  ADMIN_USERS_OVERDRAFTS: 'adminUsersOverdrafts',
  ADMIN_REPORT_DROPDOWN: 'adminReportDropdownd',
  ADMIN_REPORT_DYNAMICS: 'adminReportDynamics',
  ADMIN_SYSTEM_EMAILS: 'adminSystemEmails',
  ADMIN_USERS_LIMITS: 'adminUsersLimits',
  ADMIN_UNAPPROVED_CREATIVES: 'adminUnapprovedCreatives',
  ADMIN_CAMPAIGNS_DROPDOWN: 'adminCampaignsDropdown',
  ADMIN_COUNTRIES_BID_LIMITS: 'adminCountriesBidLimits',
  ADMIN_TOPUPS_DROPDOWN: 'adminTopupsDropdown',
  ADMIN_BIDS_DROPDOWN: 'adminBidsDropdown',
  ADMIN_SYSTEM_DROPDOWN: 'adminSystemDropdown',
  ADMIN_SITES_DROPDOWN: 'adminSitesDropdown',
  ADMIN_CHANGELOG: 'adminChangelog',

  // PUBLISHER MODULES
  PUBLISHER_DASHBOARD: 'publisherDashboard',
  PUBLISHER_SITES: 'publisherSites',
  PUBLISHER_STATISTIC: 'publisherStatistic',
  PUBLISHER_PROFILE: 'publisherProfile',
  PUBLISHER_PAYMENT: 'publisherPayment',
  PUBLISHER_PAYMENTS_REQUEST: 'publisherPaymentsRequest',
  PUBLISHER_PAYMENTS_SETTINGS: 'publisherPaymentsSettings',
  PUBLISHER_REFERRAL: 'publisherReferral',
  // PUBLISHER_WIDGETS: 'publisherWidgets',
  PUBLISHER_CUSTOMIZE_WIDGET: 'publisherCustomizeWidget',
  PUBLISHER_LOGIN_HISTORY: 'PUBLISHERLoginHistory',
  PUBLISHER_EXCHANGE_SETTINGS: 'publisherExchangeSettings',
  PUBLISHER_REPORT_DROPDOWN: 'publisherReportDropdown',
  PUBLISHER_REPORT_EXCHANGE_SENT: 'publisherReportExchangeSent',
  PUBLISHER_REPORT_EXCHANGE_RECEIVED: 'publisherReportExchangeReceived',
  PUBLISHER_REPORT_DYNAMICS: 'publisherReportDynamic',


  // ADVERTISER MODULES
  ADVERTISER_DASHBOARD: 'advertiserDashboard',
  ADVERTISER_TOPUPS: 'advertiserTopups',
  ADVERTISER_LOGIN_HISTORY: 'advertiserLoginHistory',
  ADVERTISER_REFERRAL: 'advertiserReferral',
  ADVERTISER_PROFILE: 'advertiserProfile',
  ADVERTISER_CAMPAIGNS: 'advertiserCampaigns',
  ADVERTISER_CREATIVES: 'advertiserCreatives',
  ADVERTISER_REPORT: 'advertiserReport',
  ADVERTISER_REPORT_DROPDOWN: 'advertiserReportDropdown',

  //SYSTEM MODULES
  LOGOUT: 'logout',
  ADMIN_FAST_LOGIN: 'Log in as',


  //DEV MODULES
  DEV_BUILDER_DROPDOWN: 'devBuilderDropdown',
  DEV_BUILDER_MODULE: 'devModuleBuilder',
}