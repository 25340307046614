import { createSlice } from '@reduxjs/toolkit';

import packageJson from '../../../package.json'

const currentInfo = { version: packageJson.version, name: packageJson.name, description: packageJson.description }

const states = {
  v1: {
    dashboard: {
      header: {
        active: true,
        fixed: true,
      },
      footer: {
        active: true,
        fixed: true
      },
      menu: {
        opened: true,
        responsive: false,
        numOfUnapprovedCreatives: { display: false, number: 0 },
        numOfUnapprovedCampaigns: { display: false, number: 0 }
      },
      theme: 'semi-dark',
    },
    temp: {
      splashScreen: true
    },
    groupModal: {
      opened: false,
      id: null
    }

  }
}

let initialState = states.v1

export const appMigration = {
  1: () => states.v1
}

export const appReducer = createSlice({
  name: 'app',
  initialState,
  reducers: {
    switchMenuOpened: (state, action) => {
      state.dashboard.menu.opened = action.payload ?? !state.dashboard.menu.opened
    },
    changeMenu: (state, action) => {
      state.dashboard.menu = { ...state.dashboard.menu, ...action.payload }
    },
    changeHeader: (state, action) => {
      state.dashboard.header = { ...state.dashboard.header, ...action.payload }
    },
    changeFooter: (state, action) => {
      state.dashboard.footer = { ...state.dashboard.footer, ...action.payload }
    },
    changeDashboard: (state, action) => {
      state.dashboard = { ...state.dashboard, ...action.payload }
    },
    changeInfo: (state, action) => {
      state.info = { ...state.info, ...action.payload }
    },
    switchModalOpened: (state, action) => {
      state.groupModal.opened = action.payload.opened;
      state.groupModal.id = action.payload.id;
    },
  },
});

export const { switchMenuOpened, switchModalOpened, changeHeader, changeFooter, changeDashboard, changeInfo, changeMenu } = appReducer.actions;

export const selectInfo = (state) => state.app.info;
export const selectDashboard = (state) => state.app.dashboard;
export const selectHeader = (state) => state.app.dashboard.header;
export const selectFooter = (state) => state.app.dashboard.footer;
export const selectMenu = (state) => state.app.dashboard.menu;
export const selectTheme = (state) => state.app.dashboard.theme;
export const selectSplashScreen = (state) => state.app.animations.splashScreen;
export const selectGroupModal = (state) => state.app.groupModal;
export const selectMenuOpened = (state) => state.app.dashboard.menu.opened;
export const selectNumOfUnnaprovedCreatives = (state) => state.app.dashboard.menu.numOfUnapprovedCreatives;
export const selectNumOfUnnaprovedCampaigns = (state) => state.app.dashboard.menu.numOfUnapprovedCampaigns;


export default appReducer.reducer;